import { _userCards } from '../../_mock';
import { dispatch } from '../store';
import { DEFAULT_RESPONSE } from '../../../src/config';
import axios from '../../utils/axios';
import { createSlice, current } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

const WEBSITE_ID = 1;

const OFFERS = [
  {
    "id": "2",
    "createdAtUTC": "2022-05-23T17:40:53.517Z",
    "updatedAtUTC": "2022-05-23T17:40:53.517Z",
    "updatedBy": "1",
    "isActive": true,
    "createdBy": "1",
    "clientId": "4",
    "name": "mcgregorfastdiscount",
    "description": "discount of mcgregorfast site",
    "shortDescription": "mcgregorfast site ",
    "collectionAddress": "0x7dc33b42a4970892163c0f30bda8f54dea9fd6ed",
    "maxRedeemableDays": 10,
    "validFromUtc": "2022-05-23T17:32:47.000Z",
    "validTillUtc": "2023-05-23T17:32:47.000Z",
    "imageUrl": "mintpsimageurl",
    "redirectUrl": "https://www.mcgregorfast.com/",
    "offerCodeParameter": "discountcode",
    "status": 1
  }
];

function objFromArray(array: any[], key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

function convertProxyObjectToObj(proxyObj: any) {
  return cloneDeep(proxyObj);
}

const initialState:any = {
  projects: {},
  files : {},
  uploadingFiles: {
    "1" : {
      files : [],
      startUploading : false
    }
  }
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    getProjects(state, action) {
      const projects = action.payload;
      const originalState = convertProxyObjectToObj(state);
      originalState.projects.byId = objFromArray(projects);
      originalState.projects.allIds = Object.keys(originalState.projects.byId);
      state.projects = {...originalState.projects};
    },

    getProject(state, action) {
      const project = action.payload;

      state.project.byId[project.id] = project;
      if (!state.project.allIds.includes(project.id)) {
        state.project.allIds.push(project.id);
      }
    },
    
    getDocuments(state, action) {
      const { response, projectId } = action.payload;
      let projectFiles = { [projectId] : response.data.data || [] };
      state.files = {...state.files, ...projectFiles};
    },


    updateLocalDocuments(state, action) {
      const { response, projectId } = action.payload;
      let existingFilesCollection = state.files[projectId];
      let projectFiles = { [projectId] : [response.data.data, ...existingFilesCollection,] || [] };
      state.files = {...state.files, ...projectFiles};
    },

    uploadProjectFiles(state, action) {
      const { projectId, files, startUploading } = action.payload;    
      state.uploadingFiles = { ...state.uploadingFiles, [projectId] : { files: files, startUploading: startUploading }}
    }
  },
});

export const { uploadProjectFiles }  = slice.actions;
export default slice.reducer;

// export const handleUploadProjectFiles = async (payload:any) =>  {
//   const { projectId, files, startUploading } = payload;
//   dispatch(slice.actions.uploadProjectFiles(payload))
//   console.log({ projectId, files, startUploading  })
// }

export const getProjects = async (data:any) => {
  try{
    const response =  await axios.post(`project/filter`);
    let files: string | any[] | null = response.data.data;
    if(files === null ){
      return [];
    }
    dispatch(slice.actions.getProjects(files))
    return response.data.data;
  } catch(e: any) {
    console.log(e);
    return DEFAULT_RESPONSE
  }
};

export const createProject = async (data: any) => {
  const response =  await axios.post(`project/create`, data);
  return response;
};

export const deleteProject = async (id: string) => {
  let payload = {
    data : {
      id : id,
      'disableProject': true 
    }
  };

  try{
    const response =  await axios.delete(`project/toggle`, payload);
    return response.data.data;
  } catch(e: any) {
    console.log(e);
    return false;
  }
}

export const updateProject = async (data: any, projectId: string) => {
  const response =  await axios.put(`project/update`, data);
  return response;
};

export const getDocuments = async (projectId:number) => {
  try{
    const response =  await axios.get(`project/${projectId}/documents`);
    const files = response ? response.data : [];
    dispatch(slice.actions.getDocuments({ response, projectId }));
    return response;
  } catch(e: any) {
    console.log(e);
    return DEFAULT_RESPONSE
  }
};

export const deleteProjectDocuments = async (projectId: string, id : any) => {
  try{
    const response =  await axios.delete(`project/${projectId}/delete-document/${id}`);
    return response.data.data;
  } catch(e: any) {
    console.log(e);
    return false;
  }
}

export const uploadDocuments = async (projectId:string, file: { progress: any } , config:any ) => {
  try{
    const response =  await axios.post(`project/${projectId}/upload-document`, file, config);
    if(response.data){
      dispatch(slice.actions.updateLocalDocuments({response, projectId}))
      // getDocuments(parseInt(projectId));
    }
    
    return response;
  } catch(e: any) {
    console.log(e);
    return DEFAULT_RESPONSE
  }
};

export const getPresignedFilesUrl = async (projectId:string, files:any ) => {
  try{
    const response =  await axios.post(`project/${projectId}/presigned-document-url`, files);
    const preSignedUrls = response.data.data;
    if(preSignedUrls === null){
      return [];
    }
    return preSignedUrls;
  } catch(e: any) {
    console.log(e);
    return DEFAULT_RESPONSE
  }
};

export const preSignedFileUpload = async (preSignedUrl:string, file: File | string, config:any ) => {
  let formData:any = new FormData();
  formData.append('file', file);
  try{
    const response =  await axios.put(preSignedUrl, formData, config);
    if(response.data){
      console.log({ response });
    }
    return response;
  } catch(e: any) {
    console.log(e);
    return DEFAULT_RESPONSE
  }
};



export const getGenerativeContent = async (projectId:number, query:string) => {
    try{
       const response =  axios.get(`haystack-engine/files/search_generative_qa?project_id=${projectId}&query=${query}`);
       return response;
     } catch(e: any) {
       console.log(e);
       return DEFAULT_RESPONSE
     }
 };


export const getExtractiveContent = async (projectId:number, query:string) => {
    try{
       const response =  axios.get(`haystack-engine/files/search_extractive_qa?project_id=${projectId}&query=${query}`);
       return response;
     } catch(e: any) {
       console.log(e);
       return DEFAULT_RESPONSE
     }
 };


