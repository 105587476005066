export const memberRoles = [
    {
      "id": 6,
      "createdAtUTC": "2022-08-23T07:41:42.107Z",
      "isActive": true,
      "name": "PARTNER_ANALYST",
      "displayName": "Partner Analyst",
      "externalIDPIdentifier": "rol_hJz3tCd2yIeL6hLD",
      "description": "This role may be added or removed from a user by the Partner Owner or the Partner Admin user. The user with the Analyst role does not have access to the Partner Management panel. This user can only view the analytics dashboard by default."
    },
    {
      "id": 5,
      "createdAtUTC": "2022-08-23T07:41:42.107Z",
      "isActive": true,
      "name": "PARTNER_PERKS_MANAGER",
      "displayName": "Partner Perks Manager",
      "externalIDPIdentifier": "rol_Y4aw5DcPxeBjbdxP",
      "description": "This role may be added or removed from a user by the Partner Owner or the Partner Admin user. The user with the Perks Manager role does not have access to the Partner Management panel. This role will by default be limited to managing the perks associated"
    },
    {
      "id": 4,
      "createdAtUTC": "2022-08-23T07:41:42.107Z",
      "isActive": true,
      "name": "PARTNER_EDITOR",
      "displayName": "Partner Editor",
      "externalIDPIdentifier": "rol_giq34aOs9a6P5dqT",
      "description": "This role may be added or removed from a user by the Partner Owner or the Partner Admin user. The user with the Editor role does not have access to the Partner Management panel."
    },
    {
      "id": 3,
      "createdAtUTC": "2022-08-23T07:41:42.107Z",
      "isActive": true,
      "name": "PARTNER_PUBLISHER",
      "displayName": "Partner Publisher",
      "externalIDPIdentifier": "rol_hUyv3xLD12vKOPJD",
      "description": "This role may be added or removed from a user by the Partner Owner or the Partner Admin user. The user with the Publisher role does not have access to the Partner Management panel."
    },
    {
      "id": 2,
      "createdAtUTC": "2022-08-23T07:41:42.107Z",
      "isActive": true,
      "name": "PARTNER_ADMIN",
      "displayName": "Partner Admin",
      "externalIDPIdentifier": "rol_HDnCCMqHAoaGXsF7",
      "description": "This role has all the rights of the Partner Owner, except for a few that include setting SSO for the Organization, accessing invoicing details and the right to create/edit/delete/deactivate Owners."
    },
    {
      "id": 1,
      "createdAtUTC": "2022-08-23T07:41:42.107Z",
      "isActive": true,
      "name": "PARTNER_OWNER",
      "displayName": "Partner Owner",
      "externalIDPIdentifier": "rol_HD6n7WpKnqBAnb5d",
      "description": "User who owns a Partner-Environment, meaning that they may exist as the only user for the system"
    }
  ];
