import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem, Divider } from '@mui/material';
// @types
import { UserInvitation } from '../../../../@types/user';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import useLocales from 'src/hooks/useLocales';
// ----------------------------------------------------------------------

type Props = {
  row: UserInvitation;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onResendInvite: VoidFunction;
  onDeleteInvite: VoidFunction;
};

export default function InvitationsTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onResendInvite,
  onDeleteInvite,
}: Props) {
  const theme = useTheme();

  const { translate } = useLocales();
  
  const { inviter, invitee, role, status, created_at, expires_at, can_manage } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const breakWord = {
    wordBreak: "break-word"
  }

  return (
    <TableRow hover selected={selected}>
      {/**<TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>**/}

      <TableCell align="left">
      <Typography variant="subtitle2" sx={breakWord}>
        {invitee}
      </Typography>
      </TableCell>
      <TableCell align="left">
      <Typography variant="subtitle2" noWrap>
        {role}
      </Typography>
        </TableCell>
      <TableCell align="left">
      <Typography variant="subtitle2" noWrap>
        {created_at}
        </Typography>
      </TableCell>
      <TableCell align="left">
      <Typography variant="subtitle2" noWrap>
        {expires_at}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="subtitle2" sx={breakWord}>
          {inviter}
        </Typography> 
      </TableCell>
      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(status === 'Expired' && 'error') || 'warning'}
          sx={{ textTransform: 'capitalize' }}
        >
          {status}
        </Label>
      </TableCell>
      {can_manage && <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onResendInvite();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:email-fill'} />
                {translate('users.invitation_list.buttons.re_invite')}
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  onDeleteInvite();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                {translate('users.invitation_list.buttons.delete')}
              </MenuItem>
            </>
          }
        />
      </TableCell>}
    </TableRow>
  );
}
