import { ReactNode, useEffect, createContext } from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
import { analyticsDefaultSettings, ANALYTICS_STORAGE_KEY } from '../config';

const initialState: any = {
 	...analyticsDefaultSettings,
 	onChangeCampaign: () => {},
 	onChangeCurrency: () => {},
  onSetCollections: () => {}
};

const AnalyticsSettingsContext = createContext(initialState);


type AnalyticsSettingsProviderProps = {
  children: ReactNode;
};


function AnalyticsSettingsProvider({ children }: AnalyticsSettingsProviderProps) {
  const [analyticsSettings, setAnalyticsSettings] = useLocalStorage(ANALYTICS_STORAGE_KEY, {
    selectedCampaign: initialState.selectedCampaign,
    selectedCurrency: initialState.selectedCurrency,
    selectedWebTab: initialState.selectedWebTab,
    collections: initialState.collections,
    collectionsWithIds: initialState.collectionsIdsObj,
    version: initialState.version
  });

    const onChangeCampaign = (campaignId: string) => {
	    setAnalyticsSettings({
	      ...analyticsSettings,
	      selectedCampaign: {
          tab1: ( analyticsSettings.selectedWebTab == "tab1" || analyticsSettings.selectedCampaign.tab1 == "" ? campaignId : analyticsSettings.selectedCampaign.tab1 ), 
          tab2: ( analyticsSettings.selectedWebTab == "tab2" || (analyticsSettings.selectedCampaign.tab2 == "" && analyticsSettings.collectionsWithIds[campaignId] && analyticsSettings.collectionsWithIds[campaignId].deployedContractAddress) ? campaignId : analyticsSettings.selectedCampaign.tab2 )
	    }
      });
	 };

	 const onChangeCurrency = (currency: string) => {
	    setAnalyticsSettings({
	      ...analyticsSettings,
	      selectedCurrency: currency,
	    });
	 };


   const onChangeWebTab = (val: string) => {
      setAnalyticsSettings({
        ...analyticsSettings,
        selectedWebTab: val,
      });
   };

   const onSetCollections = (list: any[]) => {
      let collections:any = {};
      let collectionsWithIds:any = {};
      list.map(val => {
        if(val.deployedContractAddress) {
          collections[val.deployedContractAddress.toLowerCase()] = {name: val.name, id: val.id};
        }
        collectionsWithIds[val.id] = {name: val.name, id: val.id, deployedContractAddress: val.deployedContractAddress ?? ""};
      });
      setAnalyticsSettings({
        ...analyticsSettings,
        collections: collections,
        collectionsWithIds: collectionsWithIds
      });
   };


  return (
    <AnalyticsSettingsContext.Provider
      value={{
        ...analyticsSettings,

        onChangeCampaign,
        onChangeCurrency,
        onSetCollections,
        onChangeWebTab
      }}
    >
      {children}
    </AnalyticsSettingsContext.Provider>
  );
}

export { AnalyticsSettingsProvider, AnalyticsSettingsContext };