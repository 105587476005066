// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  cancel: `cancel`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  back_button: 'Back',
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  theme: {
    labels: {
      light: 'Light',
      dark: 'Dark'
    }
  },
  collections: {
    collectionList: {
      add_button: 'Add Collection',
      deletePopupText: 'Are you sure you want to delete?',
      deletePopupCancel: 'Cancel',
      deletePopupDelete: 'Delete'
    },
    steps: {
      step1: {
        metadata_heading: 'Contract Metadata',
        metadata_subheading: 'Settings to organize and distinguish between your different contracts.',
        payout_heading: 'Payout Settings',
        payout_subheading: 'Where should any funds generated by this contract flow to.',
        primary_heading: 'Primary Sales',
        primary_subheading: 'Determine the address that should receive the revenue from initial sales of the assets.',
        royalties_heading: 'Royalties',
        royalties_subheading: 'Determine the address that should receive the revenue from royalties earned from secondary sales of the assets.',
        image_upload_text_1: 'Allowed *.jpeg, *.jpg, *.png, *.gif',
        image_upload_text_2: 'max size of',
        labels: {
          name: 'Collection Name *',
          symbol: 'Symbol *',
          price: 'Price (ETH) *',
          description: 'Description *',
          nftQuantity: 'Quantity *',
          primarySalesReceipientAddress: 'Recipient Address *',
          royalityReceipientAddress: 'Recipient Address *',
          royalityFraction: 'Percentage (%) *',
        }
      },
      step2: {
        nft_metadata_heading: 'NFT Metadata',
        nft_metadata_subheading: 'Add NFT Name, Description and Media. Allowed Images (*.jpeg, *.jpg, *.png, *.gif)',
        optional_heading: 'Optional',
        optional_subheading: ' If you need more contents, please add properties and Opensea attributes.',
        properties: 'Properties',
        opeanSea_meta: 'OpeanSea Advanced Metadata',
        background_color: 'Must be a six-character hexadecimal with a pre-pended #.',
        external_url: 'This is the URL that will appear below the asset’s image on OpenSea and will allow users to leave OpenSea and view the item on your site.',
        cancelButton: 'Cancel',
        updateButton: 'Update',
        submitButton: 'Submit',
        image_upload_text_1: 'Allowed *.jpeg, *.jpg, *.png, *.gif',
        image_upload_text_2: 'max size of',
        labels: {
          name: 'NFT Name *',
          description: 'Description *',
          nftQuantity: 'Total of NFTs *',
          traitType: 'Trait Type',
          traitValue: 'Value',
          backgroundColor: 'Background Color',
          externalURL: 'External URL',
        }
      },
      step3: {
        heading : 'Collection has been created',
        description: `If you need to add more NFT, please click “Add More NFT”. 
        Otherwise, please click “Done” button to go back to Collection page.`
      }
    },
    validations: {
      name_required: 'Collection Name is required',
      nftQuantity_required: 'Quantity is required',
      symbol_required: 'Symbol is required',
      price_required: 'Price is required',
      description_required: 'Description is required',
      primarySalesReceipientAddress_required: 'Primary Sale Recipient Address is required',
      royalityReceipientAddress_required: 'Royality Recipient Address is required',
    },
    messages: {
      create: 'Create Collection successfully',
      update: 'Update Collection successfully',
      publishPopupHeading: 'Do you want to publish?',
      publishPopupSubHeading :'Please review below details before publishing. If you need to update any contents, please click “No, I don’t want”. Otherwise, please click “Yes, I will publish”',
      publishPopupCancelButton: 'No, I don’t want',
      publishPopupSubmitButton: 'Yes, I will publish',
    }
  },
  general_analytics: {
    common: {
      home_ref: 'Home',
      analytics_dir_ref: 'Analytics',
      heading: 'Analytics',
      title: 'General: Analytics',
      details: 'Details'
    },
    tabs: {
      tab1: {label: "Web 2", value: "tab1"},
      tab2: {label: "Web 3", value: "tab2"}
    },
    buttons: {
      view_nfts: 'NFT analytics',
      search_nft: 'Search',
      show_more: 'Show More'
    },
    header: {
      view_all_campaign: 'View Collection',
    },
    error: {
      alert: 'Please select any collection to view details!'
    },
    nft_popover: {
      title: 'Fill in the inputs to see the data',
      labels: {
        nftId: 'Enter Item ID'
      },
      validations: {
        'nft_id': 'Item ID is required',
        number: 'Value should be an integer'
      }
    },
    nft: {
      details: {
        title: 'Item'
      },
      transactions: {
        title: 'NFT Trades Over Time',
        table: {
          time: 'Time',
          from: 'From',
          to: 'To',
          quantity: 'Quantity',
          price: 'Price'
        }
      },
      title: 'NFT Item Profiler'
    },
    sections: {
      section_1: 'Revenue',
      section_2: 'Mint',
      section_3: 'Web 2',
    },
    data: {
      total_revenue: {
        title: 'Total'
      },
      days_60: {
        title: '60 Day Revenue'
      },
      days_90: {
        title: '90 Day Revenue'
      },
      days_1: {
        title: '1 Day'
      },
      days_7: {
        title: '7 Day'
      },
      days_30: {
        title: '30 Day'
      },
      campaign_revenue: {
        title: 'Price and Volume',
        subtitle: 'Price & Volume',
        price: 'Price',
        volume: 'Volume'
      },
      secondary_market: {
        title: 'Floor Price',
        avg_price: 'Avg Price',
        min_price: 'Min Price',
        max_price: 'Max Price',
        price: 'Price',
        items_sold: 'Volume'
      },
      transactions_buyers: {
        title: 'Transactions & Buyers',
        transactions: 'Total Transactions',
        buyers: 'Unique Buyers'
      },
      ownership: {
        title: 'Ownership',
        series1: 'Unique Wallets',
        series2: 'Holding Single Item',
        series3: '% Wallets',
        yaxisLeft: '# Wallets',
        yaxisRight: '% Wallets'
      },
      summary: {
        title: 'Holding Time',
        transactions: 'Total Transactions',
        buyers: 'Unique Buyers'
      },
      funnel: {
        title: 'Funnel',
      },
      sold_items: {
        title: 'Average Sale',
        total: 'Total'
      },
      minted: {
        title: 'Minted',
        total: 'Total'
      },
      users: {
        title: 'Buyers',
        total: 'Total'
      },
      sales: {
        title: 'Latest Sale Price'
      },
      visitors: {
        title: 'Visitors'
      },
      page_views: {
        title: 'Page Views'
      },
      page_speed: {
        title: 'Page Speed'
      },
      holders: {
        title: 'Holders'
      },
      dates: {
        start: 'Start Date',
        end: 'End Date'
      }
    }
  },
  users: {
    common: {
      home_ref: 'Home',
      team_dir_ref: 'Team',
      search_label: 'Search user...',
      new_member_button: 'New Member',
    },
    list: {
      title: 'Team',
      page_title: 'Members: List',
      page_name: 'Team',
      table: {
        label_name: 'Name',
        label_role: 'Role',
        label_email: 'Email',
        label_is_verified: 'Verified',
        label_status: 'Status',
      },
      tabs: {
        all: 'all',
        active: 'Active',
        inactive: 'Inactive',
        team_members: 'Team Members',
        invited_members: 'Invited Members'
      },
      buttons: {
        edit: 'Edit',
        reactivate: 'Reactivate',
        inactive: 'Inactive',
        cancel: 'Cancel',
        yes: 'Yes!',
        delete : 'Delete',
        delete_confirmation: 'Are you sure you want to delete this ?'
      },
      text: {
        update_confirmation: 'Are you sure you want to update this ?'
      }
    },
    invitation_list: {
      title: 'Team',
      page_title: 'Invitations: List',
      new_member_button: 'New Member',
      page_name: 'Invitations',
      table: {
        label_inviter: 'Email', 
        label_roles: 'Role', 
        label_created_at: 'Created At', 
        label_expires_at: 'Expires At', 
        label_invitee: 'Created By', 
        label_status: 'Status', 
        label_actions: '', 
      },
      status: {
        all: 'all',
        pending: 'Pending',
        expired: 'Expired',
      },
      buttons: {
        re_invite: 'Re-invite',
        delete: 'Delete',
        cancel: 'Cancel'
      },
      text: {
        delete_confirmation: 'Are you sure you want to delete ?'
      }
    },
    add_edit: {
      title_new: 'Invite a new member',
      page_title: 'Invite a new member',
      page_title_edit: 'Edit member',
      title_edit: 'Edit member',
      page_name: 'New Member',
      validations: {
        email: 'Email Is Required',
        role: 'Role Is Required'
      },
      labels: {
        email: 'Email Address *',
        role: 'Select Role *',
        heading: {
          add: 'Add New Member',
          edit: 'Edit Member'
        }
      },
      buttons: {
        send_new_invite: 'Send',
        edit_invite: 'Save Changes',
      },
      messages: {
        new_success: 'Invitation sent successfully!',
        edit_success: 'Invitation updated successfully!',
        request_unsuccessful: 'Request Unsuccessful!',
        request_unsuccessful_already_exist: 'Failed! User is already registered!',
        role_update_success: 'Role Updated Successfully!',
        activate: 'User activated',
        inactivate: 'User inactivated'
      }
    }
  },
  user_account: {
    common: {
      page_title: 'User: Account Settings',
      home_ref: 'Home',
      title: 'Personal Information'
    },
    profile: {
      validations: {
        given_name: 'First name is required',
        family_name: 'Last name is required',
      },
      messages: {
        success: 'Profile updated successfully!',
        error: ''
      },
      text: {
        avatar_text_1: 'Allowed *.jpeg, *.jpg, *.png',
        avatar_text_2: 'max size of'
      },
      labels: {
        given_name: 'First Name *',
        family_name: 'Last Name *',
        email: 'Email',
        role: 'Role',
        heading: 'Personal Information',
        sub_heading: 'You can update your First and Last name.'
      },
      buttons: {
        save: 'Update',
        uploading: 'Uploading Image'
      }
    },
    change_password: {
      validations: {
        new_password: {
          min_length: 'Password must be at least 8 characters',
          required: 'New Password is required',
        },
        confirm_password: {
          matches: 'Passwords must match'
        },
        text: {
          min_length: 'Minimum 8 characters',
          uppercase: 'At least 1 upper letter (A-Z)',
          lowercase: 'At least 1 lower letter (a-z)',
          numeric: 'At least 1 number (0-9)',
          special_character: 'At least 1 special character',
        }
      },
      messages: {
        success: "We've just sent you an email to reset your password!",
        failure: ''
      },
      labels: {
        new_password: 'New Password',
        old_password: 'Old Password',
        confirm_password: 'Confirm New Password',
        heading: 'Change Password',
        text_1: 'We will send an email with link to change password. ',
        text_2: 'Don’t have a link?  ',
      },
      buttons: {
        save: 'Save Changes',
        reset_password_link_1: 'Send Reset Password Link',
        reset_password_link_2: 'Resend Link'
      }
    }
  },
  profile_setup: {
    title: 'Profile Setup',
    labels: {
      heading: 'You’re Almost Done',
      sub_heading: 'Your account has been created. Please update your first name and last name to continue.',
    },
    buttons: {
      sign_up: 'Sign Up',
      continue: 'Continue',
    },
    success: {
      labels: {
        heading: 'Congratulations',
        sub_heading: 'Finally, your account has been signed up.'
      },
      buttons: {
        go_to: 'Go to Dashboard'
      }
    }
  },
  home: {
    banner: {
      labels: {
        welcome_title: 'Welcome to',
        welcome_desc: '  Lawbotics,ai is a new platform for law firms.',
      },
      buttons: {
        play_video: 'Play Video'
      }
    },
    faqs: {
      labels: {
        title: 'FAQ',
        sub_title: 'How can I help you?',
        desc: 'You still need help? No worries, Here are the general questions and answers.',
      },
      list: {
        1: {
          heading: 'How do I create a new NFT collection?',
          detail: 'Navigate to “Collections” in the left side menu, click the green “add collection” button on the top right hand corner and follow the step by step instructions.'
        },
        2: {
          heading: 'Can I see different campaigns in Analytics?',
          detail: 'Yes, select the “view collection” drop down under the header and select the collection you would like to view.'
        },
        3: {
          heading: 'Are all analytics shown in real time?',
          detail: 'We have different cadence for respective reports which varies from every 15 minutes to ones per day.'
        },
        4: {
          heading: 'What is the conversion rate between ETH, USD and EUR?',
          detail: 'We use live coingecko.com data to get the conversion rates for our reports.'
        },
        5: {
          heading: 'How do I change user permissions?',
          detail: 'Navigate to the “Team” section, click the 3 dots on the right hand side of the username and select edit.'
        },
        6: {
          heading: 'How do I delete my account?',
          detail: 'You currently have to email our support team at support@Lawbotics.ai.'
        },
        7: {
          heading: 'I need additional support.',
          detail: 'Please email us at support@Lawbotics.ai and we will respond within 24hrs. Please put urgent in the title for all urgent requests.'
        },
        
      }
    },
    team_directory: {
      labels: {
        title: 'TEAM DIRECTORY',
        sub_title: 'Team Members',
        desc: 'You still need help? No worries, Here are the general questions and answers.',
        view_all: 'View All'
      }
    },
    dark_mode: {
      labels: {
        easy_switch: 'EASILY SWITCH BETWEEN STYLES',
        title: 'Dark Mode',
        desc: 'A dark theme is easier on the eyes.',
      }
    }
  },
  projects : {
    site_details: {
      title: 'Site Details',
      site_logo: 'Site Logo',
      labels: {
        site_name: 'Site Name',
        site_color: 'Site Color'
      }
    },
    partners: {
      title: 'All Partners'
    },
    documents: {
      upload_successful : 'Document Uploaded Successful!',
      uploads_successful : 'Documents Uploaded Successful!',
      upload_unsuccessful : 'Error while uploading Document!'
    },
    labels: {
      title: 'Projects',
      heading: 'Projects',
      add_new_project: 'Add New Project',
      cards: {
        offers: 'Offers',
        following: 'Following',
        total_posts: 'Total Post'
      },
      add_edit_project: {
        image: 'Allowed *.jpeg, *.jpg, *.png'
      },
      deletePopupText: 'Are you sure you want to delete?',
      inputs: {
        project_name: 'Project Name *',
        project_description: 'Description *'
      }
    },
    errors: {
      project_name_required: 'Name is required',
      project_image_required: 'Image is required',
      delete: 'Could not delete!'
    },
    success: {
      delete: 'Project deleted successfully!',
      create_project: 'Project created successfully!',
      update_project: 'Project updated successfully!',
    },
    input : {
      search : 'Search...'
    },
    buttons: {
      refresh : 'Refresh',
      search : 'Search',
      edit: 'Edit',
      offers: 'Offers',
      settings: 'Settings',
      delete: 'Delete',
      add_edit_project: {
        save: 'Save Changes'
      },
      deletePopupCancel: 'Cancel',
      deletePopupDelete: 'Delete',
      upload : 'Upload'
    },
    document : {
      title : 'Documents',
      heading : 'Documents',
      explore_search_case_documents : 'Explore and Search Case Documents',
    },
    explore : {
      title : 'Explore',
      heading : 'Explore : Files and Documents',
      explore_search_case_documents : 'Explore and Search Case Documents',
    },
    offers: {
      list: {
        title: 'All Offers',
        heading: 'All Offers',
        labels: {
          image: 'Image',
          offer: 'Offer',
          validity: 'Validity',
          description: 'Description',
          collectionAddress: 'Collection Address',
          maxRedeemableDays: 'Max Redeemable Days',
          redirectUrl: 'Redirect URL',
          offerCodeParameter: 'Offer Code',
          status: 'Status'
        },
        buttons: {
          view: 'View Project',
          edit: 'Edit',
          delete: 'Delete',
        }
      },
      buttons: {
        add_new: 'Add New Project'
      },
      rules: {
        validations: {
          title: "Title is required",
          details: "Details is required",
          subtitle: "Subtitle is required",
          termsAndConditions: "T&C is required",
          couponCodeQuantity: "Quantity is required",
          couponCode: "Code is required",
          offerId: "Offer ID is required",
        }
      }
    }
  },

  nft_perks: {
    site_details: {
      title: 'Site Details',
      site_logo: 'Site Logo',
      labels: {
        site_name: 'Site Name',
        site_color: 'Site Color'
      }
    },
    partners: {
      title: 'All Projects'
    },
    labels: {
      title: 'Projects',
      heading: 'Projects',
      add_new_vendor: 'Add New Project',
      cards: {
        offers: 'Offers',
        following: 'Following',
        total_posts: 'Total Post'
      },
      add_edit_project: {
        image: 'Allowed *.jpeg, *.jpg, *.png'
      },
      deletePopupText: 'Are you sure you want to delete?',
      inputs: {
        vendor_name: 'Project Name *',
        vendor_description: 'Description *'
      }
    },
    errors: {
      vendor_name_required: 'Name is required',
      vendor_project_required: 'Project is required',
      vendor_image_required: 'Image is required',
      delete: 'Could not delete!',
      delete_document: 'Could not delete document!',
      
    },
    success: {
      delete: 'Project deleted successfully!',
      create_vendor: 'Project created successfully!',
      update_vendor: 'Project updated successfully!',
      delete_document: 'Document deleted successfully!',
    },
    buttons: {
      edit: 'Edit',
      offers: 'Offers',
      settings: 'Settings',
      delete: 'Delete',
      add_edit_vendor: {
        save: 'Save Changes'
      },
      deletePopupCancel: 'Cancel',
      deletePopupDelete: 'Delete'
    },
    offers: {
      list: {
        title: 'All Offers',
        heading: 'All Offers',
        labels: {
          image: 'Image',
          offer: 'Offer',
          validity: 'Validity',
          description: 'Description',
          collectionAddress: 'Collection Address',
          maxRedeemableDays: 'Max Redeemable Days',
          redirectUrl: 'Redirect URL',
          offerCodeParameter: 'Offer Code',
          status: 'Status'
        },
        buttons: {
          view: 'View Offer',
          edit: 'Edit',
          delete: 'Delete',
        }
      },
      buttons: {
        add_new: 'Add New Offer'
      },
      rules: {
        validations: {
          title: "Title is required",
          details: "Details is required",
          subtitle: "Subtitle is required",
          termsAndConditions: "T&C is required",
          couponCodeQuantity: "Quantity is required",
          couponCode: "Code is required",
          offerId: "Offer ID is required",
        }
      }
    }
  },
  error_page: {
    custom_error: {
      button: {
        go_home: 'Go to Home',
        go_login: 'Go to Login',
      },
      text: {
        timestamp: 'Logging out in'
      },
      keys: {
        404: {title: 'User not found!', desc: 'User account does not exist or has been deactivated. Please contact support!'},
        409: {title: 'User role changed!', desc: "User access/role details do not match, please re-login, or contact support!"},
        access_denied: {title: 'Access Denied!', desc: "You don't have the access to the platform anymore. Please contact support!"},
        invalid_request: {title: 'Error!', desc: ""},
        500: {title: 'Error!', desc: ""},
      }
    }
  }
};

export default en;
