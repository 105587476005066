import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import auth0Axios from '../../utils/auth0Axios';
//
import { dispatch } from '../store';
import Resizer from "react-image-file-resizer";
import { AUTH0_API, IMAGE_SIZEX, IMAGE_SIZEY, QUALITY, CROP_ALLOWED_EXT } from '../../config';
// ----------------------------------------------------------------------

const ALLOWED_EXT = ['gif', 'GIF'];

const resizeFile = (file: any, sizeX:number, sizeY:number, quality:number) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      sizeX,
      sizeY,
      fileExt(file.name),
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

const fileExt = (filename:any) => { return filename.split('.').pop(); }

export const fileUpload = async (file: any, uploadFolder: any, resize: boolean = false, sizeX: number = IMAGE_SIZEX, sizeY: number = IMAGE_SIZEY, quality: number = QUALITY) => {
  try {
    const formData = new FormData();
    if(resize) {
      if(CROP_ALLOWED_EXT.indexOf(fileExt(file.name)) == -1) {
        throw new Error("File not allowed");
      }
      const image:any = await resizeFile(file, sizeX, sizeY, quality);
      formData.append('file', image);
    } else {
      formData.append('file', file);
    }
    formData.append('uploadFolder', uploadFolder);
    const response = await axios.post('/user/upload', formData , {
      headers: {'Content-Type': 'multipart/form-data'}
    });
    return response.data.data;
  } catch (e: any) {
    return {status: 402, message: e.message};
  }
};

