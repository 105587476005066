import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { UserManager } from '../../@types/user';
// ----------------------------------------------------------------------

function objFromArray(array: any[], key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState: any = {
  isLoading: false,
  email: "",
  role: [0],
  error: null,
  invite: [],
  delete: [],
  users: [],
  id: ""
};

const slice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // ON SEND Invite
    onSendInvite(state, action) {
      const payload = action.payload;
      const { email, role } =
        payload;

      const newPayload = {
        email: email,
        role: [Number(role)]
      };

      state.invite.push(newPayload);
    },
    // ON Delete Invite
    onDeleteInvite(state, action) {
      const payload = action.payload;
      const { obj } = payload;

      const newPayload = {
        id: obj.id
      };
      state.delete.push(newPayload);
    },
    onGetUsers(state) {
      const newPayload = {};
      state.users.push(newPayload);
    },
    onGetInvitations(state) {
      const newPayload = {};
      state.users.push(newPayload);
    },
    onSetUsers (state, payload) {
      state.users = payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onSendInvite, onGetUsers, onGetInvitations, onDeleteInvite, onSetUsers } = slice.actions;

// ----------------------------------------------------------------------

export const sendInvite = async (email: string, roles: Array<number>) => {
  var role = roles[0];
  var uri = roles[0] == 1 ? '/user/manage/owner/invite' : '/user/manage/invite';
  const response = await axios.post(uri, {
    email,
    role
  });
  return response.data.data;
};


export const updateRole = async (user: any, role: number, updatedRole: number) => {
  var uri = role == 1 ? '/user/manage/owner/update-roles' : '/user/manage/update-roles';
  const response = await axios.post(uri, {
    "userId": user.id,
    "from": [
      user.roleId
    ],
    "to": [
      updatedRole
    ]
  });
  return response.data;
};

export const deleteInvite = async (obj: any) => {
  var role = obj?.role_id;
  var uri = obj?.role_id == 1 ? '/user/manage/owner/invite' : '/user/manage/invite';
  const response = await axios.delete(uri, {
    data: { "id": obj.id, "roles": [role] }
  });
};


export const updateUserStatus = async (user: any, role: number) => {
  var uri = role == 1 ? '/user/manage/owner/toggle-roles' : '/user/manage/toggle-roles';
  const isActive = user.isActive ? true: false;
  const response = await axios.post(uri, {
    "id": user.id,
    "disableRoles": isActive,
    "existingRoles": [
      user.roleId
    ]
  });
  response.data.isActive = isActive;
  return response.data;
};

export const getUsers = async (limit?:number | null, user?:any) => {
  const response = await axios.post('/user/filter');
  var tableDataObj: any = {};
  const tableDataArr: UserManager[] = [];
  response.data.data.map((object: any, index: number) => {
    if(!limit || (index < limit)) {
      const name = ((object.givenName ? object.givenName : "").concat(" ", (object.familyName ? object.familyName : ""))).trim(); 
      tableDataObj = {};
      tableDataObj.id = object.id;
      tableDataObj.email = object.email;
      tableDataObj.avatarUrl = object.profilePicture;
      tableDataObj.isVerified = object.isActive;
      tableDataObj.status = object.partners[0].roles[0].isActive ? "Active" : "Inactive";
      tableDataObj.isActive = object.partners[0].roles[0].isActive;
      tableDataObj.role = object.partners[0].roles[0].displayName;
      tableDataObj.name = name;
      tableDataObj.roleId = object.partners[0].roles[0].id;
      tableDataObj.canManage = object.partners[0].roles[0].id >= user?.details?.partners[0]?.roles[0]?.id && user?.id != object.externalIDPIdentifier;
      tableDataArr.push(tableDataObj);
    }
  });
  dispatch(slice.actions.onSetUsers(tableDataArr));
  return tableDataArr;
};


export const getInvitations = async () => {
  const response = await axios.get('/user/invitations');
  return response.data.data;
};

