import { paramCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import useLocales from 'src/hooks/useLocales';
// @mui
import {
  Box,
  Card,
  Container,
  CircularProgress,
  Grid,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// hooks
import useSettings from 'src/hooks/useSettings';
// @types
import { UserManager } from 'src/@types/user';
// _mock_
import { _userList } from 'src/_mock';
import { projectSearchTypes, project } from 'src/_mock';
// components
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { getDocuments, getExtractiveContent, getGenerativeContent } from 'src/redux/slices/projects';
import { getRoleOptions } from 'src/redux/slices/roles';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';

// sections
import { UserTableToolbarV2, UserHeader } from 'src/sections/@dashboard/user/list';
import ProjectSearchTabs from './ProjectSearchTabs';
import { Typography } from '@mui/material';
import DocumentCard from './DocumentCard';
import AnswerCards from './AnswerCards';
import { Chip } from '@mui/material';

export default function Explore() {

    const [documentData,  setDocumentData]  = useState([])

    const [answerData,  setAnwersData]  = useState([])

    const [ showExample, setShowExample] = useState(true);
  
  const initialValues: UserManager[] = [];

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const { hash } = useLocation();

  const {projectId, projectName } = useParams();
  
  
  const [userRole, setUserRole] = useState(user?.details?.partners[0]?.roles[0]?.id);


  const [loading, setLoading] = useState(false);

  const [filterName, setFilterName] = useState('');

  const [filterRole, setFilterRole] = useState('Write Answer');
  
  const { translate } = useLocales();


  const [roles, setRoles] = useState(projectSearchTypes);
  
  const ROLE_OPTIONS = getRoleOptions(roles);


  const getSearchHandler = async () => {
    if(!projectId){
      throw new Error("ProjectId is undefined")
    }
    setLoading(true);
    setShowExample(false);
    let searchType = (filterRole === 'Write Answer') ? getGenerativeContent(parseInt(projectId), filterName) : getExtractiveContent(parseInt(projectId), filterName);
    await searchType.then((response) => {
    let data = response ? response.data : [];

    setDocumentData(data.documents);
    setAnwersData(data.answers);
    if(!Boolean(data.documents.length) && !Boolean(data.answers.length)){
      setShowExample(true);
    }
      setLoading(false);
    }).catch(e => {
      setLoading(false);
    });
  }


  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
  };

  const handleFilterRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterRole(event.target.value);
  };


//   const denseHeight = dense ? 60 : 80;

  return (
    <Page title={translate('projects.explore.heading')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>

      <h3>{translate('projects.document.explore_search_case_documents')}</h3>

      <Grid container spacing={0} mt={3} mb={4}>
              <Grid item xs={6} md={12}>
                <ProjectSearchTabs
                  filterName={filterName}
                  filterRole={filterRole}
                  onFilterName={handleFilterName}
                  onFilterRole={handleFilterRole}
                  optionsRole={ROLE_OPTIONS}
                  getResultFn={getSearchHandler}
                />
              </Grid>
      </Grid>

        <Box />
        
        
          { loading && <Box textAlign="center" display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'start'} gap={2}><CircularProgress /> <Typography>Searching...</Typography> </Box>}
          
          {
            showExample ? <Box mb={3}>
            <h2>Example Usage</h2>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>What is Paul's Criminal Record ?</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>What conflicts can be found from witness reports from the case ?</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>Summerize the 2021 Interview with Paul's mother ?</Typography>
          </Box> : null
          }
        

        <>

          <Scrollbar>
            {/* {loading && <Box textAlign="center"><CircularProgress /></Box>} */}
            {!loading && <Box>
              <AnswerCards answerData={answerData} />
              <DocumentCard documentData={documentData} />
            </Box> }
          </Scrollbar>

          
        </>
      </Container>
      
    </Page>
  );
}

// ----------------------------------------------------------------------

