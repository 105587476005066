import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../../hooks/useAuth';
// utils
import { fData } from '../../../../utils/formatNumber';
// _mock
import { countries } from '../../../../_mock';
// components
import { CustomFile } from '../../../../components/upload';
import { userUpdate } from '../../../../redux/slices/profile';
import { fileUpload } from '../../../../redux/slices/uploads';
import {
  AccountResetPassword,
} from '../account';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { S3_FOLDERS } from '../../../../config';
import useLocales from 'src/hooks/useLocales';
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../../components/hook-form';

// ----------------------------------------------------------------------

type FormValuesProps = {
  givenName: string;
  familyName: string;
  email: string;
  profilePicture: CustomFile | string | null;
  role: number | 0;
};

const subHeadingStyle = {
    fontSize: 12,
    color: "#919EAB"
  }

export default function AccountGeneral() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { user, authUserUpdate } = useAuth();
  const [profilePicture, setProfilePicture] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const UpdateUserSchema = Yup.object().shape({
    givenName: Yup.string().required(translate('user_account.profile.validations.given_name')),
    familyName: Yup.string().required(translate('user_account.profile.validations.family_name')),
  });

  const defaultValues = {
    givenName: user?.details?.givenName || '',
    familyName: user?.details?.familyName || '',
    email: user?.email || '',
    profilePicture: user?.details?.profilePicture || '',
    role: user?.details?.partners ? user?.details?.partners[0].roles[0].displayName : "",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [user])

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await new Promise((resolve) => {
        userUpdate(data.givenName, data.familyName, profilePicture).then(() => {
        enqueueSnackbar(translate('user_account.profile.messages.success'));
        authUserUpdate();
        navigate(PATH_DASHBOARD.general.app)
        }).catch(e => {
          enqueueSnackbar(e.message[0], { variant: 'error' });
        });
        setTimeout(resolve, 500)
      });
    } catch (error) {
      enqueueSnackbar(error.message[0], { variant: 'error' });
    }
  };

  const onCancel = () => {
    reset()
  }

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      setIsUploading(true);
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'profilePicture',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        fileUpload(file, S3_FOLDERS.profilePicture, true).then(res => {
          if(res.status == 402) {
            throw Error(res.message);
          }
          setProfilePicture(res.fileKey);
          setIsUploading(false);
        }).catch(e => {
          setValue('profilePicture', user?.details?.profilePicture);
          enqueueSnackbar(typeof e.message == 'string' ? e.message : e.message[0], { variant: 'error' });
          setIsUploading(false);
        });
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Card sx={{ py: 10, px: 3, textAlign: 'center', height: '100%' }}>
            <RHFUploadAvatar
              name="profilePicture"
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                    height: '100%'
                  }}
                >
                  {translate('user_account.profile.text.avatar_text_1')}
                </Typography>
              }
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={9}>
          <Card sx={{ p: 3 }}>
            <Typography variant="subtitle1">
              {translate('user_account.profile.labels.heading')}
            </Typography>
             <Box sx={subHeadingStyle}>
              {translate('user_account.profile.labels.sub_heading')}
            </Box>
            <Box
              pt={4}
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField autoComplete="nope" name="givenName" label={translate('user_account.profile.labels.given_name')} />
              <RHFTextField autoComplete="nope" name="familyName" label={translate('user_account.profile.labels.family_name')} />
              <RHFTextField name="email" label={translate('user_account.profile.labels.email')} disabled />
              <RHFTextField name="role" label={translate('user_account.profile.labels.role')} disabled />
            </Box>

            <Stack direction="row" justifyContent="flex-end" spacing={1.5} sx={{ mt: 3 }}>
                {/*<Button color="inherit" variant="contained" onClick={onCancel}>
                  {translate('cancel')}    
                </Button>*/}
              {!isUploading && <LoadingButton disabled={isUploading} type="submit" variant="contained" loading={isSubmitting}>
                {translate('user_account.profile.buttons.save')}
              </LoadingButton>}
              {isUploading && <LoadingButton disabled={true} variant="contained">
                {translate('user_account.profile.buttons.uploading')}
              </LoadingButton>}
            </Stack>
            <AccountResetPassword />
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
