import useResponsive from "src/hooks/useResponsive";
import UserAccountV2 from "./dashboard/UserAccountV2";
import { Box } from "@mui/material";
import useSettings from "src/hooks/useSettings";
import { HEADER, NAVBAR } from "src/config";
import useCollapseDrawer from "src/hooks/useCollapseDrawer";
import { useState } from "react";
import DashboardHeader from "src/layouts/dashboard/header";
import NavbarHorizontal from "src/layouts/dashboard/navbar/NavbarHorizontal";
import NavbarVertical from "src/layouts/dashboard/navbar/NavbarVertical";
import { styled } from "@mui/material";


// ----------------------------------------------------------------------

type MainStyleProps = {
    collapseClick: boolean;
  };
  
  const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
  })<MainStyleProps>(({ collapseClick, theme }) => ({
    flexGrow: 1,
    paddingTop: HEADER.MOBILE_HEIGHT + 24,
    paddingBottom: HEADER.MOBILE_HEIGHT + 24,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
      paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
      width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
      transition: theme.transitions.create('margin-left', {
        duration: theme.transitions.duration.shorter,
      }),
      ...(collapseClick && {
        marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
      }),
    },
  }));
  

  
const Register: React.FC = () => {
    const [open, setOpen] = useState(false);

    const isDesktop = useResponsive('up', 'lg');
    const { themeLayout } = useSettings();
    const verticalLayout = themeLayout === 'vertical';

    const { collapseClick, isCollapse } = useCollapseDrawer();




    if (verticalLayout) {
        return (
          <>
            <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />
    
            {isDesktop ? (
              <NavbarHorizontal />
            ) : (
              <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            )}
    
            <Box
              component="main"
              sx={{
                px: { lg: 2 },
                pt: {
                  xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                  lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
                },
                pb: {
                  xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                  lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
                },
              }}
            >
              <UserAccountV2 />
            </Box>
          </>
        );
      }

    return (
        <>
        <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <UserAccountV2 />
      </MainStyle>
    </Box>
        </>
    );
  };
  
  export default Register;
  