import { Box, Card, Typography } from "@mui/material";

const DocumentCard = ({ documentData }) => {
    // const { content } = documentData.document;

    // if(Boolean(content)) return null;  
    
    return(
        <>
        {
                    documentData.map( data => {
                        const { content , meta : { filename }, score } = data;
                        return(
                            <Box mb={2}>
            <Card sx={{
                padding: 3
            }}>
            <Box
                py={2}
                sx={{
                display: 'flex',
                alignItems:'start',
                justifyContent:'space-between',
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
            >
                <Box sx={{
                    display : 'flex',
                }}>
                    <Typography variant="h6" gutterBottom>{'Reference Material'}</Typography>
                    {filename && <Typography gutterBottom sx={{
                        ml:2,
                        px: 1,
                        py: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        bgcolor: 'grey.50012',
                        height:'fit-content'
                    }}>
                        {filename }
                    </Typography>}
                </Box>
                {
                    Boolean(score) && <Typography variant="h6" gutterBottom style={{ minWidth: 150 }}>
                        Relevance: {Number(score).toPrecision(2)}
                    </Typography>
                }
                
            </Box>
            <Typography variant="body1" gutterBottom>{content}</Typography>
            </Card>
        </Box>
                        )
                    })
                }
                </>
    )
}

export default DocumentCard;
