import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import axiosWithouAuth from '../../utils/auth0Axios';
// _mock
import { memberRoles } from '../../_mock';
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState: any = {
  isLoading: false,
  data: memberRoles,
  error: null,
  params: [],
  nftCollection: []
};

const slice = createSlice({
  name: 'params',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // ON SEND Invite
    onGetRoles(state) {
      const newPayload = {};
      state.params.push(newPayload);
    },
    // ON SEND Invite
    onGetUserRoleFromExternalIDP(state) {
      const newPayload = {};
      state.params.push(newPayload);
    },
    setNftForCollection (state, payload) {
      state.nftCollection = payload;
    }
  }
})

// Reducer
export default slice.reducer;

// Actions
export const { onGetRoles, onGetUserRoleFromExternalIDP, setNftForCollection } = slice.actions;


export const saveCollection = async (collection: any) => {
  const response =  await axios.post('/collection/create', collection);
  return response.data.data;
};

export const upDateCollection = async (collection: any) => {
  const response =  await axios.put('/collection/update', collection);
  return response.data.data;
};

export const saveNFT = async (nftData: any) => {
    const response =  await axios.post('/collection/nft/create', nftData);
    return response.data.data;
};

export const updateNft = async (nftData: any) => {
  const response =  await axios.put('/collection/nft/update', nftData);
  return response.data.data;
};

export const getCollection = async () => {
  const response =  await axios.post('/collection/filter');
  return response.data.data;
};

export const getCointractData = async (url:string) => {
  const response =  await axios.get(url);
  return response.data;
};

export const deleteCollection = async (collection_id: string) => {
    const response =  await axios.delete(`/collection/toggle/`, {
      data: {
        id: collection_id,
        disableCollection: true
      }
    });
    return response.data.data;
}
export const deleteNFT = async (nft_id: string) => {
    const response =  await axios.delete(`/collection/nft/delete/${nft_id}`);
    return response.data.data;
}

interface InftCollectionobj {
  nftCollectionId: number
  id?: number
}

export const getNftsForCollecion = async (nftCollectionId: InftCollectionobj) => {
    const response =  await axios.post(`/collection/nft/filter`, nftCollectionId);
    response.data.data = response.data.data && response.data.data.filter((item: Record<string, any>) => item.isActive === true);
    if(!nftCollectionId.id) {
      dispatch(slice.actions.setNftForCollection(response.data.data));
    }
    return response.data.data;
}

export const getCollectionByID = async (id: number) => {
    const response =  await axios.post(`/collection/filter`, {id});
    return response.data.data;
}

export const getPublishContentByID = async (id: number) => {
  const response =  await axios.get(`/collection/content-to-publish/${id}`);
  return response.data.data;
}

export const getPublishContentByTransxID = async (id: number, transactionHash: string) => {
  const response =  await axios.get(`collection/check-publish-status/${id}/${transactionHash}`);
  return response.data.data;
}
interface InftCollectionPublish{
  id: number;
  transactionHash: string;
  publishedByAddress: string;
}

export const publishContract = async (data: InftCollectionPublish) => {
  const response =  await axios.post(`/collection/publish`, data);
  return response.data.data;
}


export const getUserRoleFromExternalIDP = (roles: any, key: string) => {
  var role = "";
  if(roles) {
    roles.forEach((obj: any) => {
      if(obj.externalIDPIdentifier == key) {
        role = obj.displayName;
      }
    })
  }
  return role;
};

