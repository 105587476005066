import React, { ReactElement, useRef } from 'react';
import { useEffect, useState } from 'react';
import { FileError, useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Button } from '@mui/material';
// type
import { UploadMultiFileProps } from './type';
//
import BlockContent from './BlockContent';
import RejectionFiles from './RejectionFiles';
import MultiFilePreview from './MultiFilePreview';
import FilePreview from './FilePreview';
import { useSnackbar } from 'notistack';
import { dispatch } from 'src/redux/store';
import { uploadProjectFiles } from 'src/redux/slices/projects';
import { useParams } from 'react-router';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

export default function UploadMultiFile({
  accept,
  error,
  showPreview = false,
  files,
  onUpload,
  onRemove,
  onRemoveAll,
  helperText,
  sx,
  fileProgress,
  handleUploadedFile,
  uploadedFiles,
  handleFiles,
  handleUploadDialog,
  isUploading,
  getDocumentsHandler,
  ...other
}: UploadMultiFileProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections, acceptedFiles } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    validator: duplicateValidator,
    ...other,
  });

  const { enqueueSnackbar } = useSnackbar();

  const { projectId } = useParams();

  function duplicateValidator(currentFile: { name: string | File }): FileError | FileError[] | null {
    const getFileIndex = files?.findIndex((_file: { name: string | File; }) => _file.name === currentFile.name);

    if(getFileIndex !== -1){
      // enqueueSnackbar( 'Duplicate "'+ currentFile.name.slice(0,10) + '" Found ', { variant: 'error' });
      enqueueSnackbar( 'Duplicate File Found ', { variant: 'error' });
      return null;
    }
    return null;
  }
  

  // !Object.keys(fileProgress).every( (file: string | number) => fileProgress[file] < 100)

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          borderBottomLeftRadius:'3px',
          borderBottomRightRadius:'3px',
          background:"#161c24"
        }}
      >
        <input {...getInputProps()} />

        <BlockContent />
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
      
      <MultiFilePreview isUploading={isUploading} handleUploadedFile={handleUploadedFile} fileProgress={fileProgress} showProgress={true} files={files} showPreview={showPreview} onRemove={onRemove} />

      {files.length > 0 && (
        <Box sx={{ background: '#161c24', pr:1, py:2, borderBottomLeftRadius:'10px', borderBottomRightRadius:'10px' }}>
          {
            isUploading ? <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            <Button size="small" variant="contained" onClick={ () => {
              onRemoveAll && onRemoveAll();
              handleUploadDialog && handleUploadDialog(false);
              getDocumentsHandler && getDocumentsHandler();
              dispatch(uploadProjectFiles({ projectId, startUploading : false }));
            }
            }>
              Done
            </Button>
          </Stack> : 
          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            <Button color="inherit" size="small" onClick={onRemoveAll}>
              Remove all
            </Button>
            <Button size="small" variant="contained" onClick={onUpload}>
              Upload files
            </Button>
          </Stack>
          }
        </Box>
      )}

      {helperText && helperText}
    </Box>
  );
}
