import GeoJSON from 'geojson';
import { range } from 'd3-array';
import { scaleQuantile } from 'd3-scale';

// ----------------------------------------------------------------------

export function updatePercentiles(
  featureCollection: GeoJSON.FeatureCollection<GeoJSON.Geometry>,
  accessor: (f: GeoJSON.Feature<GeoJSON.Geometry>) => number
): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  const { features } = featureCollection;

  const scale = scaleQuantile().domain(features.map(accessor)).range(range(9));

  return {
    type: 'FeatureCollection',
    features: features.map((f) => {
      const value = accessor(f);

      const properties = {
        ...f.properties,
        value,
        percentile: scale(value),
      };

      return { ...f, properties };
    }),
  };
}

export function convertSize(bytes: number): string {
  if (bytes < 1024) {
    return bytes + " B";
  } else if (bytes < 1024 * 1024) {
    const kilobytes = (bytes / 1024).toFixed(2);
    return kilobytes + " KB";
  } else {
    const megabytes = (bytes / (1024 * 1024)).toFixed(2);
    return megabytes + " MB";
  }
}
