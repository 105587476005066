import { CURRENCIES, NFT_REPORT_PERIOD, ANALYTICS_TAB_FEATURES, ANALYTICS_STORAGE_KEY } from 'src/config';
import { AnalyticsSettingsProvider, AnalyticsSettingsContext } from '../contexts/AnalyticsContext';

export const getFeatureExist: (key:string, webTabsSelected:string) => boolean = function(key:string, webTabsSelected: string): boolean {
  return ANALYTICS_TAB_FEATURES[webTabsSelected as keyof typeof ANALYTICS_TAB_FEATURES].indexOf(key) > -1
}


export const checkAndUpdateAnalyticsVersion = () => {
  try {
    const analyticsStorage:string = localStorage.getItem(ANALYTICS_STORAGE_KEY) ?? "{}";
    const analyticsVersion = JSON.parse(analyticsStorage).version;
    const settingContext = AnalyticsSettingsContext['_currentValue' as keyof typeof AnalyticsSettingsContext];

    if(!analyticsVersion || (settingContext && settingContext['version' as keyof typeof settingContext] > analyticsVersion)) {
      localStorage.removeItem(ANALYTICS_STORAGE_KEY);
    }
  } catch(e: any) {
    console.log(e);
    localStorage.removeItem(ANALYTICS_STORAGE_KEY);
  }
}