import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem, Divider } from '@mui/material';
// @types
import { UserManager } from '../../../../@types/user';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import useLocales from 'src/hooks/useLocales';
// ----------------------------------------------------------------------

type Props = {
  row: UserManager;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function MemberTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const theme = useTheme();

  const { translate } = useLocales();
  
  const { id, name, avatarUrl, email, role, isVerified, status, roleId, isActive, canManage } = row;
  
  const displayName = name ? name : email.split("@")[0];

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const breakWord = {
    wordBreak: "break-word"
  }

  return (
    <TableRow hover selected={selected}>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={displayName} src={avatarUrl} sx={{ mr: 2 }} />
        <Typography variant="subtitle2" noWrap>
          {displayName}
        </Typography>
      </TableCell>

      <TableCell align="left">
        <Typography variant="subtitle2" noWrap>
          {role}
         </Typography> 
      </TableCell>

      <TableCell align="left">
        <Typography variant="subtitle2" sx={breakWord}>
          {email}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Iconify
          icon={isVerified ? 'akar-icons:circle-check-fill' : 'akar-icons:circle-minus-fill'}
          sx={{
            width: 20,
            height: 20,
            color: 'success.main',
            ...(!isVerified && { color: 'rgba(145, 158, 171, 0.8)' }),
          }}
        />
      </TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(status === 'Inactive' && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {status}
        </Label>
      </TableCell>

      {canManage && <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={isActive ? 'akar-icons:circle-minus-fill' : 'akar-icons:circle-check-fill'} />
                {!isActive ? translate('users.list.buttons.reactivate') : translate('users.list.buttons.inactive')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'clarity:pencil-solid'} />
                {translate('users.list.buttons.edit')}
              </MenuItem>
            </>
          }
        />
      </TableCell>}
    </TableRow>
  );
}
