import { useState } from 'react';
// @mui
import { Tabs, Tab } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useLocales from 'src/hooks/useLocales';
// ----------------------------------------------------------------------
import { PATH_DASHBOARD } from '../../../../routes/paths';
import Iconify from 'src/components/Iconify';

export default function UserTabs(props:any) {

  const { translate } = useLocales();
  
  const navigate = useNavigate();

  const PAGES_OPTIONS = [
    {
      text: 'General',
      nav:  '/general', //'General',
      icon : 'mdi:user-box'
    },
    {
      text: 'Billing',
      nav: PATH_DASHBOARD.general.billing,
      icon : 'material-symbols:receipt'
    },
    {
      text: 'Users',
      nav: PATH_DASHBOARD.general.members,
      icon : 'mdi:user-group'
    },
    {
      text: 'Folders',
      nav: PATH_DASHBOARD.general.folders,
      icon : 'ph:folders-fill'
    },
    {
      text: 'Change Password',
      nav: PATH_DASHBOARD.general.change,
      icon : 'material-symbols:key'
    },
    // {
    //   text: translate('users.list.tabs.team_members'),
    //   nav: PATH_DASHBOARD.general.members
    // },
    // {
    //   text: translate('users.list.tabs.invited_members'),
    //   nav: PATH_DASHBOARD.general.invitations
    // }
  ];

  return (
    <Tabs
      allowScrollButtonsMobile
      variant="scrollable"
      scrollButtons="auto"
      value={props.selectedTab}
    >
      {PAGES_OPTIONS.map((tab) => (
        <Tab icon={<Iconify icon={tab.icon} />} disableRipple key={tab.text} label={tab.text} value={tab.text} onClick={() => navigate(tab.nav)} />
      ))}
    </Tabs>
  );
}
