import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// _mock
import { memberRoles } from '../../_mock';
import { dispatch } from '../store';
import { CURRENCIES, ANALYTICS_START_DATE, DEFAULT_RESPONSE } from '../../../src/config';
import { fNextDate } from '../../utils/formatTime';

// ----------------------------------------------------------------------
const initialState: any = {
  isLoading: false,
  data: memberRoles,
  error: null,
  params: [],
  nftCollection: []
};

const slice = createSlice({
  name: 'params',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // ON SEND Invite
    onGetRoles(state) {
      const newPayload = {};
      state.params.push(newPayload);
    },
    // ON SEND Invite
    onGetUserRoleFromExternalIDP(state) {
      const newPayload = {};
      state.params.push(newPayload);
    },
  }
})

// Reducer
export default slice.reducer;

// Actions
export const { onGetRoles, onGetUserRoleFromExternalIDP, } = slice.actions;


export const getAnalyticsData = async (contractAddress: string) => {
    try{
      const response =  await axios.get(`/analytics/get-nft-stats?contractAddress=${contractAddress}`);
      return response.data.data;
    } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
};

export const getNftDailyVolume = async (contractAddress: string) => {
  try{
    const response =  await axios.get(`/analytics/get-nft-daily-volume?contractAddress=${contractAddress}`);
    return response.data.data;
  } catch(e: any) {
    console.log(e);
    return DEFAULT_RESPONSE
  }
};

export const getNftHourlyVolume = async (contractAddress: string) => {
    try{
      const response =  await axios.get(`/analytics/get-nft-hourly-volume?contractAddress=${contractAddress}`);
      return response.data.data;
    } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
};

export const getNftDailyFloorPrice = async (contractAddress: string) => {
    try{
      const response =  await axios.get(`/analytics/get-nft-daily-floor-price?contractAddress=${contractAddress}`);
      return response.data.data;
    } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
};

export const getNftHourlyFloorPrice = async (contractAddress: string) => {
    try{
      const response =  await axios.get(`/analytics/get-nft-hourly-floor-price?contractAddress=${contractAddress}`);
      return response.data.data;
    } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
};

export const getNftDailyTB = async (contractAddress: string) => {
    try{
      const response =  await axios.get(`/analytics/get-nft-daily-transaction-volume?contractAddress=${contractAddress}`);
      return response.data.data;
    } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
};

export const getNftHourlyTB = async (contractAddress: string) => {
    try{
      const response =  await axios.get(`/analytics/get-nft-hourly-transaction-volume?contractAddress=${contractAddress}`);
      return response.data.data;
    } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
};

export const getFunnelData = async (id:number) => {
  try{
    const response =  await axios.get(`/analytics/nft-customer-statistics/${id}`);
    return response.data.data;
    } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
};

export const getCurrenciesConvertedValues = async () => {
    const response =  await axios.post(`/analytics/value-conversion`, {
      "value": CURRENCIES.ETH.value,
      "fromCurrency": CURRENCIES.ETH.key,
      "toCurrencies": [
        "USD", "EUR"
      ]
    });
    const currencies: typeof CURRENCIES = CURRENCIES;
    response.data.data.toCurrencies.forEach((obj:any) => {
      currencies[obj.currency as keyof typeof CURRENCIES] = {key: obj.currency, label: obj.currency, value: obj.value}
    });
    return currencies;
};

export const getMintData = async(contractAddress: string) => {
    try{
      const response =  await axios.get(`/analytics/get-nft-transactions-summary?contractAddress=${contractAddress}`);
      return response.data.data;
    } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
}

export const getNftHoldersByDay = async(contractAddress: string) => {
    try{
      const response =  await axios.get(`/analytics/get-nft-holders-by-day?contractAddress=${contractAddress}`);
      return response.data.data;
    } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
}

export const getNftDetails = async(contractAddress: string, tokenId: string) => {
    try{
      const response =  await axios.get(`/analytics/get-nft-traits?contractAddress=${contractAddress}&tokenId=${tokenId}`);
      return response.data.data;
    } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
}

export const getNftTransactions = async(contractAddress: string, tokenId: string) => {
    try{
      const response =  await axios.get(`/analytics/get-nft-transactions?contractAddress=${contractAddress}&tokenId=${tokenId}`);
      return response.data.data;
    } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
}

export const getNftHoldingSummary = async(contractAddress: string) => {
    try{
      const response =  await axios.get(`/analytics/get-nft-holding-summary?contractAddress=${contractAddress}`);
      return response.data.data;
    } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
}

export const getWeb2TotalUsers = async(id: number) => {
  const today = new Date();
  const payload = {
    "startDate" : ANALYTICS_START_DATE,
    "endDate":  fNextDate(today),
    "collectionId": id
  }
  const response =  await axios.post(`/analytics/ga-total-users`, payload);
  return response.data.data;
}

export const getWeb2PageViews = async(id: number) => {
  try{
    const today = new Date();
    const payload = {
        "startDate" : ANALYTICS_START_DATE,
        "endDate":  fNextDate(today),
        "pagePath": "",
        "collectionId": id
    }
    const response =  await axios.post(`/analytics/ga-page-views`, payload);
  return response.data.data;
  } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
}

export const getWeb2PageLoadTime = async(id: number) => {
try{
    const today = new Date();
    const payload = {
        "startDate" : ANALYTICS_START_DATE,
        "endDate":  fNextDate(today),
        "pagePath": "",
        "collectionId": id
    }
    const response =  await axios.post(`/analytics/ga-avg-page-load-time`, payload);
    return response.data.data;
  } catch(e: any) {
      console.log(e);
      return DEFAULT_RESPONSE
    }
}








