import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, Card, Box, CircularProgress, Typography, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import useAuth from '../../../../hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import Iconify from '../../../../components/Iconify';
import { changePassword } from '../../../../redux/slices/profile';
import EmptyContent from '../../../../components/EmptyContent';
// ----------------------------------------------------------------------

type FormValuesProps = {
  newPassword: string;
  confirmNewPassword: string;
};

export default function AccountResetPassword() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const [loading, setLoading] = useState(false);
  const [sentEmailCount, setSentEmailCount] = useState(0);
  const { user } = useAuth();

  const methods = useForm();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const changePasswordStyle = {
    fontSize: 12,
    color: "#919EAB"
  }

  const linkStyle = {
    fontWeight: "bold",
    cursor: "pointer"
  }

  const onSubmit = async () => {
    setLoading(true);
    var response = await changePassword(user?.email);
    enqueueSnackbar(translate('user_account.change_password.messages.success'));
    setSentEmailCount(sentEmailCount + 1);
    setLoading(false);
  };

  return (
      <Stack spacing={3} pt={4} pb={3}>
        {loading && <Box><CircularProgress /></Box>}
        {!loading && 
          <Box>
            <Typography variant="subtitle1">
              {translate('user_account.change_password.labels.heading')}
            </Typography>
            <Box sx={changePasswordStyle}>
              {!sentEmailCount ? translate('user_account.change_password.labels.text_1') : translate('user_account.change_password.labels.text_2')}
               <Link onClick={onSubmit} sx={linkStyle}>
                {!sentEmailCount ? translate('user_account.change_password.buttons.reset_password_link_1') : translate('user_account.change_password.buttons.reset_password_link_2')}
              </Link>
            </Box>
           
          </Box>
        }
      </Stack>
  );
}
