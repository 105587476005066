import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import auth0Axios from '../../utils/auth0Axios';
//
import { dispatch } from '../store';

import { AUTH0_API } from '../../config';
// ----------------------------------------------------------------------

const initialState: any = {
  isLoading: false,
  email: "",
  familyName: "",
  role: [0],
  givenName: "",
  profilePicture: {},
  error: null,
  profile: []
};

const slice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    onGetByToken(state) {
      const newPayload = {};
      state.profile.push(newPayload);
    },
    onUserUpdate(state, action) {
      const payload = action.payload;
      const { givenName, familyName, profilePicture } =
        payload;

      const newPayload = {
        givenName: givenName,
        familyName: familyName,
        profilePicture: profilePicture
      };

      state.profile.push(newPayload);
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onGetByToken, onUserUpdate } = slice.actions;

// ----------------------------------------------------------------------

export const getByToken = async () => {
  try {
    const response = await axios.get('/user/get-by-token');
    return response.data.data;
  } catch(e) {
    return {
      error: e
    }
  }
};

export const userUpdate = async (givenName: string, familyName: string, profilePicture: any) => {
  const response = await axios.put('/user/update', {
    givenName: givenName,
    familyName: familyName,
    profilePicture: profilePicture
  } , {
    headers: {'content-type': 'application/json'}
});
  return response.data.data;
};

export const changePassword = async (email: string) => {
  let config: any =  localStorage.getItem('config')
  if(config) {
    config = JSON.parse(config);
  }
  const uri:any = AUTH0_API?.domain?.concat('/dbconnections/change_password');
  const response = await auth0Axios.post(uri, 
        {
          client_id: AUTH0_API?.clientId,
          email: email,
          connection: 'Username-Password-Authentication',
          organization: config?.id || ''
        }
      );
  return response;
}

