import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { CLIENT_LOGO, CLIENT_LOGO_LIGHT_MODE } from 'src/config';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const LOGO = theme.palette.mode === 'dark' ? CLIENT_LOGO() : CLIENT_LOGO_LIGHT_MODE();

  // OR
  // -------------------------------------------------------
  const logo = (
    // <Box
    //   component="img"
    //   src={}
    //   sx={{ width: 99.28, cursor: 'pointer', ...sx }}
    // />
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 'fontWeightBold'}} noWrap>
        Lawbotics.ai
      </Typography>
    </Box>
  );



  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink style={{
    color: 'white', textDecoration: 'none' 
  }} to="/">{logo}</RouterLink>;
}
