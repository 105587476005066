import useResponsive from "src/hooks/useResponsive";
import useSettings from "src/hooks/useSettings";
import { HEADER, NAVBAR } from "src/config";
import useCollapseDrawer from "src/hooks/useCollapseDrawer";
import { useCallback, useEffect, useState } from "react";
import DashboardHeader from "src/layouts/dashboard/header";
import NavbarHorizontal from "src/layouts/dashboard/navbar/NavbarHorizontal";
import NavbarVertical from "src/layouts/dashboard/navbar/NavbarVertical";
import { Button, Dialog, IconButton, styled } from "@mui/material";
import UserAccount from "../dashboard/UserAccount";
import Page from "src/components/Page";

import { Container, Tab, Box, Tabs } from '@mui/material';
import useLocales from "src/hooks/useLocales";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Folders from "./Folders";
import Iconify from "src/components/Iconify";
import { MultiFilePreview, UploadMultiFile } from "src/components/upload";
import { getDocuments, getPresignedFilesUrl, preSignedFileUpload, uploadDocuments } from "src/redux/slices/projects";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import { uniqBy } from "lodash";
import FileUploadAccordion from "./FileUploadAccordion";

// redux
import { useDispatch, useSelector } from '../../redux/store';
import { uploadProjectFiles } from '../../redux/slices/projects';

// ----------------------------------------------------------------------

type MainStyleProps = {
    collapseClick: boolean;
  };
  
  const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
  })<MainStyleProps>(({ collapseClick, theme }) => ({
    flexGrow: 1,
    // paddingTop: HEADER.MOBILE_HEIGHT + 24,
    paddingBottom: HEADER.MOBILE_HEIGHT + 24,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 16,
      paddingRight: 16,
      // paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
      paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
      width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
      transition: theme.transitions.create('margin-left', {
        duration: theme.transitions.duration.shorter,
      }),
      ...(collapseClick && {
        marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
      }),
    },
  }));
  

  
const Index: React.FC = () => {
    const {projectId = "1" } = useParams();
    const parsedProjectId: any = projectId;
  
    const getDocumentsHandler = async () => {
        return projectId ? getDocuments(parsedProjectId) : '';
    }


    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const isDesktop = useResponsive('up', 'lg');
    const { themeLayout } = useSettings();
    const verticalLayout = themeLayout === 'vertical';

    const { collapseClick, isCollapse } = useCollapseDrawer();

    const { themeStretch } = useSettings();

    const { translate } = useLocales();

    const { enqueueSnackbar } = useSnackbar();

    const [ showUploadDialog, setShowUploadDialog] = useState(false);

    // const [files, setFiles] = useState<(any)[]>([]);

    const uploadingFiles = useSelector((state) => state.projects.uploadingFiles);

    let { files = [], startUploading = false } = uploadingFiles[projectId || '1'] || {};
    

    const [fileProgress, setFileProgress] = useState({});

    const [ uploadedFiles, setUploadedFiles] = useState<(any)[]>([]);

    let [isUploading, setIsUploading] = useState(false);

    const [showFileUploadAccordion, setShowFileUploadAccordion] = useState(false);
    
    const getUploadProgress = (fileName: any) => (progress:any) => {
      let percentage = Math.floor((progress.loaded * 100) / progress.total)

      let uploadProgress = files?.map((_file: { name: any; progress: number; }) => {
        console.log({ fileName, name : _file.name})
        if(_file.name === fileName){
          _file.progress = percentage;
        }
        return _file;
      });

    setFileProgress((progress) => ({
      ...progress,
      [fileName]: percentage,
    }))

  }

    
    const uploadFile = async (file: any  ) => {

      const fileName: string = file.name;


      var config = {
        onUploadProgress: getUploadProgress(fileName)
      };

      
    setFileProgress((progress) => ({
      ...progress,
      [fileName]: 0,
    }))


    const changeFileProgress = (fileName: any, progress: number) => {
      // progress = {  -1 : Error and 0-99 : showing progress, 100 : Uploaded }
      let clonedFiles = [...files];
      
      let updateProgress = clonedFiles?.map((_file) => {
        console.log({ fileName, name : _file.name})
        if(_file.name === fileName){
          _file.progress = progress;
        }
        return _file;
      });

      // setFiles(updateProgress);
      dispatch(uploadProjectFiles({ projectId, files: updateProgress, startUploading : false }));
      
    }

      let formData:any = new FormData();
      formData.append('file', file);
      
      await uploadDocuments(projectId, formData, config).then((response) => {
        const data = response ? response?.data : [];
        if(data?.statusCode === 201){
          // changeFileProgress(file.name, 101);
          enqueueSnackbar(translate('projects.documents.upload_successful'));
        }else {
          changeFileProgress(file.name, -1);
          throw new Error(`Error while uploading`);
        }
      }).catch(e => {
        enqueueSnackbar(`${file.name.slice(0, 10)}... - Error while uploading`, { variant: 'error' })
      });
    }

    const handlePresignedFileUrl = async () => {
      let filesName = files.map( (file: { name: any; }) => file.name);

      let payload = {
        fileNames : filesName
      }
      await getPresignedFilesUrl(projectId, payload).then((response) => {
        const data = response ? response : [];
        if(data.length){
          data.map((url: string, index: number) => {
            let fileName = files[index].name;
            var config = {
              onUploadProgress: getUploadProgress(fileName)
            };

            setFileProgress((progress) => ({
              ...progress,
              [fileName]: 0,
            }))
            
            preSignedFileUpload(url, files[index], config);
          });
        }
      }).catch(e => {
        enqueueSnackbar('Error in gettting presigned url', { variant: 'error' });
      });
    };

    const handleFileUpload = () => {
      setIsUploading(true);
      // handlePresignedFileUrl();
      // files.map( document => uploadFile(document));
      dispatch(uploadProjectFiles({ projectId, files: files, startUploading : true }));
      setShowUploadDialog(false);
    };

    const handleDropMultiFile = useCallback(
      (acceptedFiles: File[]) => {

        const newFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            progress : 0,
            isUploaded : false,
          })
        );

        if(isUploading){
          // handle to remove already uploaded status files from list
          setIsUploading(false);
          // setFiles(newFiles);
          dispatch(uploadProjectFiles({ projectId, files: newFiles, startUploading : false }));
          return;
        }

        const uniqueFiles = uniqBy([...files, ...newFiles], 'name');
        // setFiles(uniqueFiles);
        dispatch(uploadProjectFiles({ projectId, files: uniqueFiles, startUploading : false }));
      },
      [files]
    );
  
    const handleRemoveFile = (file: any) =>  {
      setIsUploading(false);
      const filtered = files?.filter((_file: any) => _file !== file);
      // setFiles(filtered);
      dispatch(uploadProjectFiles({ projectId, files: filtered, startUploading : false }));

      const filteredNameWise = files?.filter((_file: { name: any; }) => _file.name !== file.name);
      setFileProgress(filteredNameWise);

    };

  
    const handleRemoveAllFiles = () => {
      setIsUploading(false);
      // setFiles([]);
      dispatch(uploadProjectFiles({ projectId, files: [], startUploading : false }));
      setUploadedFiles([]);
      setFileProgress({});
    };

    const handleUploadedFile = (file:any) => {
      setUploadedFiles([...uploadedFiles, file]);
    }
    

    useEffect(() => {
      if(showUploadDialog){
        setShowFileUploadAccordion(false);
      }
    }, [showUploadDialog]);

    if (verticalLayout) {
        return (
          <>
            <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />
    
            {isDesktop ? (
              <NavbarHorizontal />
            ) : (
              <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            )}
    
            <Box
              component="main"
              sx={{
                px: { lg: 2 },
                pt: {
                  xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                  lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
                },
                pb: {
                  xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                  lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
                },
              }}
            >
              <UserAccount />
            </Box>
          </>
        );
      }

    return (
        <>
        <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {/* <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} /> */}

      {/* <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> */}

      <MainStyle collapseClick={collapseClick}>
      <Page title={translate('user_account.common.page_title')}>
    
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading={translate("projects.document.heading")}
          links={[]}
          action={
            <Button
              variant="contained"
              component="label"
              fullWidth
              onClick={() => setShowUploadDialog(true)}
              startIcon={<Iconify icon={'ep:upload-filled'} />}
            >
              {translate('projects.buttons.upload')}
              <input type="button" hidden multiple />
            </Button>
          }
        />
        <Box sx={{ mb: 5 }} />
        {
        showFileUploadAccordion && files.length &&  <FileUploadAccordion onRemoveAll={handleRemoveAllFiles}>
          <MultiFilePreview isUploading={isUploading} handleUploadedFile={handleUploadedFile} fileProgress={fileProgress} showProgress={true} files={files} showPreview={(false)} onRemove={handleRemoveFile} />
      </FileUploadAccordion>
        }

          <Folders />

          {showUploadDialog && (
          <Dialog
            fullWidth
            sx={{
              bgcolor: '#161c244f'
            }}
            maxWidth="xs"
            open={showUploadDialog}
            onClose={() => null}
            PaperComponent={(props) => (
            <Box maxWidth="md" sx={{
              // bgcolor: 'grey.900',
              padding : '20px 20px',
              borderRadius: '5px',
              position:'relative'
            }}
            >
              {/* <IconButton edge="end" size="medium" onClick={(e) => {
                e.preventDefault();
                setShowUploadDialog(false)
                setShowFileUploadAccordion(true);
                getDocumentsHandler();
              }}
              sx={{
                position: 'absolute',
                top:25,
                right: 80,
                zIndex:1,
              }}>
                <Iconify icon={'eva:minus-fill'} />
              </IconButton> */}

              <IconButton edge="end" size="medium" onClick={(e) => {
                e.preventDefault();
                handleRemoveAllFiles();
                setShowUploadDialog(false)
                getDocumentsHandler();
              }}
              sx={{
                position: 'absolute',
                top:25,
                right: 40,
                zIndex:1,
              }}>
                <Iconify icon={'eva:close-fill'} />
              </IconButton>

              <UploadMultiFile
                handleUploadedFile={handleUploadedFile}
                accept={{ '.xlsx, .xls, .pdf , .docx, .doc' : [] }}
                multiple
                showPreview={false}
                files={files}
                onDrop={handleDropMultiFile}
                onRemove={handleRemoveFile}
                onRemoveAll={handleRemoveAllFiles}
                onUpload={handleFileUpload}
                fileProgress={fileProgress}
                uploadedFiles={uploadedFiles}
                // handleFiles={setFiles}
                handleUploadDialog={setShowUploadDialog}
                isUploading={isUploading}
                getDocumentsHandler={getDocumentsHandler}
              />
            </Box>
            )}
        >
          </Dialog>
          )}


      
      </Container>

    </Page>

      </MainStyle>
    </Box>
        </>
    );
  };
  
  export default Index;
  