// @mui
import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale';
// components
import { SettingsValueProps } from './components/settings/type';
// routes
import { PATH_DASHBOARD } from './routes/paths';

// @ts-ignore
const env:any = process.env.NODE_ENV === 'production'  ? window._env_ : process.env 
// API
// ----------------------------------------------------------------------

export const HOST_API = env.REACT_APP_HOST_API_KEY || '';
export const CDN_PATH = env.REACT_APP_CDN || '';
export const AUTH0_ORGANIZATION_ID = env.REACT_APP_AUTH0_ORGANIZATION_ID || '';

export const FIREBASE_API = {
  apiKey: env.REACT_APP_FIREBASE_API_KEY,
  authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.REACT_APP_FIREBASE_APPID,
  measurementId: env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const GTAG = {
  key: env.REACT_APP_GTAG_KEY
}

export const AUTH0_API = {
  clientId: env.REACT_APP_AUTH0_CLIENT_ID,
  domain: env.REACT_APP_AUTH0_DOMAIN,
  organization: env.REACT_APP_AUTH0_ORGANIZATION_ID,
  audience: env.REACT_APP_AUTH0_AUTH_API,
};

export const MAPBOX_API = env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const S3_FOLDERS = {
  profilePicture : 'profile_picture',
  nftImage : 'nft_image',
  nftAnimation : 'nft_animation',
  collectionImage : 'collection_image',
  CollectionBanner : 'collection_banner',
  VendorsImage : 'vendor_image',
  OffersImage : 'offer_image',

}
let dynamicLogo: string;
export const setLogo = (logo:string) => {
  dynamicLogo = logo;
}
export const CLIENT_LOGO = () => {
 return dynamicLogo;
}

export const CLIENT_LOGO_LIGHT_MODE = () => {
  const logoFileName = dynamicLogo.split("/").pop();
  const logoNameSplit = logoFileName ? logoFileName.split(".") : null;
  const dynamicLogoLight = logoNameSplit ? logoNameSplit[logoNameSplit.length - 2].concat("-light.", logoNameSplit[logoNameSplit.length - 1]) : "";
  return logoFileName ? dynamicLogo.replace(logoFileName, dynamicLogoLight) : dynamicLogo;
}

export const LOGO = 'assets/poweredbyonchain.png';
// export const CLIENT_LOGO = dynamicLogo

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'dark',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  }
];


// export const allLangs = [
//   {
//     label: 'English',
//     value: 'en',
//     systemValue: enUS,
//     icon: '/assets/icons/flags/ic_flag_en.svg',
//   },
//   {
//     label: 'French',
//     value: 'fr',
//     systemValue: frFR,
//     icon: '/assets/icons/flags/ic_flag_fr.svg',
//   },
//   {
//     label: 'Vietnamese',
//     value: 'vn',
//     systemValue: viVN,
//     icon: '/assets/icons/flags/ic_flag_vn.svg',
//   },
//   {
//     label: 'Chinese',
//     value: 'cn',
//     systemValue: zhCN,
//     icon: '/assets/icons/flags/ic_flag_cn.svg',
//   },
//   {
//     label: 'Arabic (Sudan)',
//     value: 'ar',
//     systemValue: arSD,
//     icon: '/assets/icons/flags/ic_flag_sa.svg',
//   },
// ];

export const defaultLang = allLangs[0]; // English

export const TEAM_DIR_LIMIT = 12;

export const analyticsDefaultSettings: any = {
  selectedCampaign: {
    tab1: "",
    tab2: ""
  },
  selectedCurrency: "ETH",
  collections: {},
  selectedWebTab: "tab1",
  version: 1.1,
  collectionsWithIds: {}
}

export const CURRENCIES = {
  "ETH": {key: "ETH", label: "ETH", value: 1},
  "USD": {key: "USD", label: "USD", value: 1288.23}, 
  "EUR": {key: "EUR", label:"EUR", value: 1318.59}
}

export const ROWS_PER_PAGE_OPTION = [10, 25, 50];

export const NFT_REPORT_PERIOD = [{text: "24 Hours", value: 1}, {text: "7 Days", value: 7}, {text: "30 Days", value: 30}];
export const ADMIN_ROLES = [1,2];

export const IMAGE_SIZEX = 350;
export const IMAGE_SIZEY = 350;
export const QUALITY = 100;
export const MAX_FILE_SIZE = 10000000;
export const LOGOUT_TIMESTAMP = 15000;

export const ALL_USERS = [1,2,3,4,5,6];
export const OWNERS_AND_ADMINS = [1,2];
export const OWNERS = [1];
export const OWNERS_AND_ADMINS_WITH_PUBLISHERS = [1,2,3];

export const COLLECTION_MANAGEMENT_RULES = {
  "canAdd": OWNERS_AND_ADMINS_WITH_PUBLISHERS,
  "canUpdate": OWNERS_AND_ADMINS_WITH_PUBLISHERS,
  "canDelete": OWNERS_AND_ADMINS_WITH_PUBLISHERS
}

export const CROP_ALLOWED_EXT = ['jpg', 'png', 'jpeg'];
export const UNAUTHRIZED_PAGES = ['/403', '/404', '/500', '/error'];

export const ANALYTICS_TAB_FEATURES = {
    "tab1": ["unpublishedCollections", "publishedCollections", "displayWeb2Data", "displayFunnelGraph"],
    "tab2": ["displayCurrencyTabs", "publishedCollections", "displayMintedData", "displayRevenueData", "displayWeb3Graphs", "displayHoldingGraph", "displayNFTProfilerTab"]
  };

export const COLLECTION_LIST_FILTER = {
  "tab1": [0],
  "tab2": [1]
}

export const ANALYTICS_START_DATE = "2022-12-10";
export const ANALYTICS_API_DATE_FORMT = "yyyy-MM-dd";
export const ANALYTICS_STORAGE_KEY = "analytics-settings";
export const DEFAULT_RESPONSE = null