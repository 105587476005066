export const faqs = [{
    id: 1,
    heading: "How do I create a new NFT collection?",
    detail: "Navigate to “Collections” in the left side menu, click the green “add collection” button on the top right hand corner and follow the step by step instructions."
  },{
    id: 2,
    heading: "Can I see different campaigns in Analytics?",
    detail: "Yes, select the “view collection” drop down under the header and select the collection you would like to view."
  },{
    id: 3,
    heading: "Are all analytics shown in real time?",
    detail: "We have different cadence for respective reports which varies from every 15 minutes to ones per day."
  },{
    id: 4,
    heading: "What is the conversion rate between ETH, USD and EUR?",
    detail: "We use live coingecko.com data to get the conversion rates for our reports."
  },{
    id: 5,
    heading: "How do I change user permissions?",
    detail: "Navigate to the “Team” section, click the 3 dots on the right hand side of the username and select edit."
  },{
    id: 6,
    heading: "How do I delete my account?",
    detail: "You currently have to email our support team at support@Lawbotics.ai."
  },{
    id: 7,
    heading: "I need additional support.",
    detail: "Please email us at support@Lawbotics.ai and we will respond within 24hrs. Please put urgent in the title for all urgent requests."
  }];