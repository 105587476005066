import { Box, Card, Typography } from "@mui/material";
import './projects.css';
const AnswerCards = ({ answerData }) => {
    
    if(Boolean(answerData.context)) return null;
    return(
       <>
       {
            answerData.map( data => {
                const { context, answer , meta : { filename }, score, offsets_in_context } = data;

                const getContext = (context) => {
                    if(offsets_in_context !== null){
                        let transformedContext = context;
                        let textIntoArr = transformedContext.split('');
                        offsets_in_context.map( hightlight => {
                            let { start, end } = hightlight;
                                textIntoArr.splice(start, 0, "<span class=hightlight>");
                                textIntoArr.splice(end, 0, "</span>");
                            })

                        textIntoArr = textIntoArr.join('');
                        return <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{__html: textIntoArr}}></Typography>
                    }
                    return <Typography variant="body1" gutterBottom>{context}</Typography>
                }
                
                return(
                    <Box mb={2}>
    <Card sx={{
        padding: 3
    }}>
    <Box
        py={2}
        sx={{
        display: 'flex',
        alignItems:'start',
        justifyContent:'space-between',
        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
        }}
    >
        <Box sx={{
            display : 'flex',
        }}>
            <Typography variant="h6" gutterBottom>Answer: {answer}</Typography>
            {filename && <Typography gutterBottom sx={{
                mx:2,
                px: 1,
                py: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                bgcolor: 'grey.50012',
                height:'fit-content',
                maxWidth: 500
            }}>
                {filename }
            </Typography>}
        </Box>
        {/* <Typography variant="h6" gutterBottom style={{
            minWidth: 150
        }}>Relevance:  {Number(score).toPrecision(2)}</Typography> */}
    </Box>
        {getContext(context)}
    </Card>
</Box>
                )
            })
        }
    </>
    )
}

export default AnswerCards;
