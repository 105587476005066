import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { LinearProgress } from '@mui/material';
import React from 'react';
import Iconify from '../Iconify';

interface FileUploadStatus {
    progress : number | undefined;
    onRemove : any;
    file : File | String;
    isUploading: any;
}

const FileUploadStatus = ({ progress, onRemove, file, isUploading }:FileUploadStatus) => {

    const getFileStatus = (progress:number|undefined) => {
        switch (progress) {

            case 0 : 
                return (
                    <>
                    <LinearProgress
                        variant="determinate"
                        value={progress || 0}
                        sx={{
                        '& .MuiLinearProgress-bar': { bgcolor: 'primary' },
                        '&.MuiLinearProgress-determinate': { bgcolor: 'grey.100' },
                        width: '100%',
                    }}
                    />
                    <Typography variant="subtitle2">{progress ? progress +'%' : ''}</Typography>
                    
                    {
                        !isUploading && <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                        <Iconify icon={'eva:close-fill'} />
                    </IconButton>
                    }
                    </>
                )
            case 100 :
                return (
                    // <Box textAlign="center" display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'start'} gap={1}><CircularProgress size={16} sx={{  color : 'warning.main' }} /> <Typography variant='subtitle2' color='warning.main' fontSize={14}>Processing</Typography> </Box>
                    <>
                        <Typography variant="subtitle2">Uplaoded</Typography>    
                        <IconButton edge="end" size="small">
                            <Iconify color="success.main" icon={'akar-icons:circle-check-fill'} />
                        </IconButton>
                    </>
                );

            // case 101 :
            //     return (
            //         <>
            //             <Typography variant="subtitle2">Done</Typography>    
            //             <IconButton edge="end" size="small">
            //                 <Iconify color="success.main" icon={'akar-icons:circle-check-fill'} />
            //             </IconButton>
            //         </>
            //     );

            case -1 :
                return (
                    <>
                    <Typography variant="subtitle2">Error </Typography>
                    <IconButton edge="end" size="small">
                        <Iconify sx={{color: 'error.main'}} icon={'gridicons:cross-circle'} />
                    </IconButton>
                    </>
                );
        
            default:
                return (
                    <>
                    <LinearProgress
                        variant="determinate"
                        value={progress || 0}
                        sx={{
                        '& .MuiLinearProgress-bar': { bgcolor: 'primary' },
                        '&.MuiLinearProgress-determinate': { bgcolor: 'grey.100' },
                        width: '100%',
                    }}
                    />
                    <Typography variant="subtitle2">{progress ? progress +'%' : ''}</Typography>
                    </>
                )
        }
    }
    return(
        <>
            <Box width={100} px={2} pr={0} display="flex" alignItems='center' justifyContent='center' gap="5px">
             { getFileStatus(progress) }
            </Box>

            {/* <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                    <Iconify icon={'eva:close-fill'} />
                </IconButton> */}
        </>
    )
}

export default FileUploadStatus;
