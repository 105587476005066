import { Stack, InputAdornment, TextField } from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import useLocales from 'src/hooks/useLocales';
import { Button } from '@mui/material';
import { useState } from 'react';
import { getDocuments } from 'src/redux/slices/projects';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
// ----------------------------------------------------------------------

type Props = {
  optionsRole: string[];
  filterName: string;
  filterRole: string;
  onFilterName: (value: string) => void;
  onFilterRole: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function UserTableToolbar({
  filterName,
  filterRole,
  onFilterName,
  onFilterRole,
  optionsRole,
}: Props) {
  const { translate } = useLocales();

  const dispatch = useDispatch();
  const {projectId, projectName } = useParams();
  const parsedProjectId: any = projectId;

  const getDocumentsHandler = async () => {
      return projectId ? getDocuments(parsedProjectId) : '';
  }

  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ py: 0, px: 0, pt: 0 }}
    >
      <TextField
        value={filterName}
        onChange={event => onFilterName(event.target.value)}
        placeholder={translate('projects.buttons.search')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
          style: {
            height: "40px",
          },
        }}
        fullWidth
      />
      <Button
        sx={{ width : 120 }}
        variant="contained"
        component="label"
        fullWidth
        onClick={() => getDocumentsHandler()}
        startIcon={<Iconify icon={'ci:arrow-reload-02'} />}
      >
        {translate('projects.buttons.refresh')}
      </Button>

    </Stack>
  );
}
