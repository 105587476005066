import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem, Divider, AvatarGroup, Box, Tooltip } from '@mui/material';
// @types
import { ProjectManager } from '../../../../@types/projects';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import useLocales from 'src/hooks/useLocales';
import { convertSize } from 'src/sections/map/geojson/utils';

// ----------------------------------------------------------------------

type Props = {
  row: ProjectManager;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function MemberTableRowV2({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {

  const { translate } = useLocales();
  
  // const { size, name, email = 'temp@temp.com', role = 1, isVerified = 1, status = 1, roleId= 1, isActive = false, canManage = false } = row;
  const { size, name, status, createdAtUTC, sizeInBytes, isActive } = row;
  let createdDate = new Date(createdAtUTC).toLocaleString();
  
 //name ? name : email.split("@")[0];

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const getFileUploadStatus = (status: any) => {
    switch (status) {
      case 1:
        return {
          color : 'success.main',
          status : 'Uploaded',
          icon : 'mdi:star'
        }
      case 2:
        return {
          color : '#EEEEEE',
          status : 'Initiated',
          icon : 'mdi:star'
        }
      case 3:
        return {
          color : '#FFE700',
          status : 'Processed',
          icon : 'mdi:star'
        }
      case 4:
        return {
          color : 'error.main',
          status : 'Failed',
          icon : 'mdi:star'
        }
      case 5:
        return {
          color : '#FFE700',
          status : 'Duplicate',
          icon : 'pepicons-pencil:duplicate-circle-filled'
        }
      default: 
        return {
          color : 'error.main',
          status : 'Error',
          icon : 'mdi:star'
        }
    }
  }

  const breakWord = {
    wordBreak: "break-word"
  }

  return (
    <TableRow hover selected={selected}>
      <TableCell align="left">
        
      <Checkbox
              checked={selected}
              onChange={() =>
                onSelectRow()
              }
            />
      </TableCell>

      <TableCell  align='justify' size='medium'>
        {/* <Avatar alt={displayName} src={avatarUrl} sx={{ mr: 2 }} /> */}
        <Box sx={{ display: 'flex'}}>
        <Iconify icon={'ph:files-fill'} sx={{ mr: 2, color : '#FFE700', width: "25px", height: "25px" }} />
        <Tooltip title={name} placement="top">
          <Typography variant="subtitle2" noWrap maxWidth={350}>
            {name}
          </Typography>
        </Tooltip>
        </Box>
      </TableCell>

      <TableCell align="left">
        <Typography variant="subtitle2" noWrap>
          {/* {role} */}
          { Boolean(name) && name?.split('.')[1]}
        </Typography> 
      </TableCell>

      <TableCell align="left">
        <Typography variant="subtitle2" sx={breakWord}>
          {convertSize(sizeInBytes)}
        </Typography>
      </TableCell>
      
      <TableCell align="center">
        <Typography variant="subtitle2" sx={breakWord}>
              { createdDate}
        </Typography>
      </TableCell>


      <TableCell align="center" sx={{ cursor: 'pointer'}}>
      <Tooltip title={getFileUploadStatus(status).status} arrow placement="top-end">
        <Box>
        <Iconify
          icon={getFileUploadStatus(status).icon}
          sx={{
            width: 20,
            height: 20,
            color: isActive && getFileUploadStatus(status).color
          }} 
        /></Box>
      </Tooltip>
      </TableCell>

      {/* {canManage && <TableCell align="right"> */}
      {true && <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              {/* <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={isActive ? 'akar-icons:circle-minus-fill' : 'akar-icons:circle-check-fill'} />
                {!isActive ? translate('users.list.buttons.reactivate') : translate('users.list.buttons.inactive')}
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'clarity:trash-solid'} />
                {translate('users.list.buttons.delete')}
              </MenuItem>
            </>
          }
        />
      </TableCell>}
      
    </TableRow>
  );
}
