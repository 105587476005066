// @mui
import { List, Box } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
//
import { NavSectionProps } from '../type';
import { ListSubheaderStyle } from './style';
import NavList from './NavList';
// hooks
import useAuth from '../../../hooks/useAuth';
// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
  const { translate } = useLocales();
  const { user } = useAuth();
  const roleID = user?.details?.partners ? user?.details?.partners[0].roles[0].id : 0;
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          {/* <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {translate(group.subheader)}
          </ListSubheaderStyle> */}

          {group.items.map((list:any) => {
          return !list.allowedUsers || (list.allowedUsers.indexOf(roleID) > -1) ?
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            /> : null
          })}
        </List>
      ))}
    </Box>
  );
}
