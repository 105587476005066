import { format, getTime, formatDistanceToNow, differenceInDays } from 'date-fns';
import { ANALYTICS_API_DATE_FORMT } from '../../src/config';
// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function sortfDate(date: Date | string | number) {
  const newDate = new Date(date);
  if(newDate.getHours() !== 0) {
    return format(new Date(date), 'dd MMM yyyy h a');
  }
  return format(new Date(date), 'dd MMM yyyy');
  
}



export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fToNowInDays(date: Date | string | number) {
  const days = differenceInDays(new Date(), new Date(date));
  return `${days}d ago`;
}

export function fCheckIfPastDate(date: Date | string | number) {
  return new Date(date).getTime() > new Date().getTime();
}

export function fCompare2Dates(date: Date | string | number, date2: Date | string | number) {
  return new Date(date).getTime() >= new Date(date2).getTime();
}

export function fNextDate(date: Date, formatPattern: string = ANALYTICS_API_DATE_FORMT, noOfDays: number = 1) {
  let tomorrow =  new Date()
  tomorrow.setDate(date.getDate() + noOfDays);
  return format(tomorrow, formatPattern);
}
