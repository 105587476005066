import { dispatch } from '../store';
import { DEFAULT_RESPONSE } from '../../../src/config';
import axios from '../../utils/axios';
import { createSlice, current } from '@reduxjs/toolkit';

const initialState:any = {
    organization : {}
};

const slice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        getDocuments(state, action) {
            const { data } = action.payload;
            state.organization = data;
        },
    },
});

export default slice.reducer;

export const getOrganizationDetails = async (hostUrl:string) => {
    try{
        const response =  await axios.get(`user/system-management/get-partner-details-by-domain/${hostUrl}`);
        const data = response ? response.data.data : [];
        dispatch(slice.actions.getDocuments({ data }));
        return data.id;
    } catch(e: any) {
        console.log(e);
        return DEFAULT_RESPONSE
}
};
