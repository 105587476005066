import { Button } from "@mui/material";
import {
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails,
    Box,
  } from "@mui/material";
  import { ReactNode, useState } from "react";
import { useParams } from "react-router";
  import Iconify from "src/components/Iconify";
import { getDocuments } from "src/redux/slices/projects";
  
  interface FileUploadAccordionProps {
    children: ReactNode;
    onRemoveAll: VoidFunction;
  }
  
  const FileUploadAccordion: React.FC<FileUploadAccordionProps> = ({ children, onRemoveAll }) => {
    const[isExpanded, setIsExpanded] = useState(true);

    const params = useParams<Partial<{ projectId: string }>>();

    const AccordionStyle = {
      marginTop: 8,
      borderRadius: 8,
      "& .MuiPaperRoot": {
        content: "none",
      },
    };
  
    // Styling for the Box component
    const boxStyle = {
      position: "fixed",
      bottom: 10,
      right: 20,
      zIndex: 9999, // Highest zIndex value
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)"
    };

    const handleAccordionExpand = () => {
        setIsExpanded(!isExpanded);
    }
  
    return (
      <Box sx={boxStyle}>
        <Accordion key={1} style={AccordionStyle} expanded={isExpanded} onChange={handleAccordionExpand}>
          <AccordionSummary
            expandIcon={
              <Iconify icon={"eva:arrow-ios-downward-fill"} width={20} height={20} />
            }
          >
            <Iconify icon={'ph:files-fill'} sx={{ mr: 2, color : '#FFE700', width: "20px", height: "20px" }} />
            <Typography variant="subtitle1">Upload Progress</Typography>

          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ color: "text.secondary" }}>{children}</Typography>
            <Button size="small" variant="contained" sx={{ mt: 2}} onClick={ () => {
              onRemoveAll && onRemoveAll();
              const projectIdNumber = parseInt(params.projectId || "", 10); // Parse projectId to a number
              if (!isNaN(projectIdNumber)) {
                getDocuments(projectIdNumber);
              }
            }
            }>
              Close All
            </Button>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };
  
  export default FileUploadAccordion;
  