// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_PROFILE = '/profile';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PROFILE = {
  root: ROOTS_PROFILE,
  general: {
    setup: path(ROOTS_PROFILE, '/setup'),
    success: path(ROOTS_PROFILE, '/success')
  }
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  login: '/login',
  error: '/error'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    secondmarket: path(ROOTS_DASHBOARD, '/second-market'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    members: path(ROOTS_DASHBOARD, '/users'),
    nft_perks: path(ROOTS_DASHBOARD, '/nft-perks'),
    nft_offers: (id: string) => path(ROOTS_DASHBOARD, `/nft-perks/offers/${id}`),
    invitations: path(ROOTS_DASHBOARD, '/users/invitations'),
    nftholder: path(ROOTS_DASHBOARD, '/nftholder'),
    general: path(ROOTS_DASHBOARD, '/general'),
    projects: path(ROOTS_DASHBOARD, '/projects'),
    billing: path(ROOTS_DASHBOARD, '/billing'),
    folders: path(ROOTS_DASHBOARD, '/folders'),
    change: path(ROOTS_DASHBOARD, '/change-password'),
  },
  
  project: {
    root: path(ROOTS_DASHBOARD, '/project'),
    new: path(ROOTS_DASHBOARD, '/project/new'),
    files: (projectId:string) => path(ROOTS_DASHBOARD, `/project/${projectId}/files`),
    explore: (projectId:string) => path(ROOTS_DASHBOARD, `/project/${projectId}/explore`),
  },

  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  nft: {
    root: path(ROOTS_DASHBOARD, '/collection'),
    new: path(ROOTS_DASHBOARD, '/collection/new'),
    list: path(ROOTS_DASHBOARD, '/collection/list'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/collection/view/${id}`),
    viewnft: (nftCollectionId:string, nftId: string) => path(ROOTS_DASHBOARD, `/collection/viewnft/${nftCollectionId}/${nftId}`),
    analytics: (nftCollectionId:string, nftId: string) => path(ROOTS_DASHBOARD, `/analytics/nft/${nftCollectionId}/${nftId}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/collection/edit/${id}`),
    editNft: (nftCollectionId: string, nftId: string) => path(ROOTS_DASHBOARD, `/collection/editExitingNft/${nftCollectionId}/${nftId}`),
    addNft: (nftCollectionId: string) => path(ROOTS_DASHBOARD, `/collection/addNft/${nftCollectionId}`),
    offers: {
      new: (id: string) => path(ROOTS_DASHBOARD, `/nft-perks/offers/new/${id}`),
      edit: (vendorID: string, offerID: string) => path(ROOTS_DASHBOARD, `/nft-perks/offers/edit/${vendorID}/${offerID}`),
    },
  },
  // nft/:nftCollectionId'
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
