export const projectSearchTypes = [
    {
      "id": 1,
      "createdAtUTC": "2022-08-23T07:41:42.107Z",
      "isActive": true,
      "name": "FIND_CONTENT",
      "displayName": "Find Content",
      "description": "Get Extrative Data"
    },
    {
        "id": 2,
        "createdAtUTC": "2022-08-23T07:41:42.107Z",
        "isActive": true,
        "name": "WRITE_ANSWER",
        "displayName": "Write Answer",
        "description": "Get Generative Data"
      },
];

export const project = [
    {
      "id": 39,
      "name": "Alex Law LLC",
      "description": "Best lawyer's in LA",
      "createdAtUTC": "2023-06-13T00:11:58.000Z",
      "isActive": true,
      "email": null,
      "partnerId": 1,
      "secret": "882a3d3c-8cfe-410b-8997-d94eefbb5bfa",
      "imageUrl": "https://lawboticsqa.blob.core.windows.net/nftdata/vendor_image/vendor_image-daf9c880-824a-4fa6-baf0-4ad23ebd6e81.png",
      "key": "f44ec670-f65d-4409-ad62-9f2961aca7cf"
  }
];
  