import { randomInArray, randomNumberRange } from "./funcs";
import _mock from "./_mock";

export const _collectionList = [...Array(24)].map((_, index) => ({
    id: _mock.id(index),
    imageURL: _mock.image.avatar(index),
    bannerImage: _mock.image.avatar(index),
    name: _mock.name.fullName(index),
    description: '',
    nftQuantity: randomNumberRange(99, 999),
    symbol: 'PFN',
    price: '10 Eth',
    nftCount: randomNumberRange(99, 999),
    primarySalesReceipientAddress: '',
    royalityReceipientAddress: '',
    royalityFraction: '',
    status: randomInArray(['published', 'unpublished']),
  }));