import { useState, ReactNode } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
import { PATH_PROFILE } from '../routes/paths';
// pages
// import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import FileUploadProvider from 'src/components/FileUploadProvider';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

const AuthGuard = ({ children }: AuthGuardProps) => {
  const { isAuthenticated, isInitialized, login, user } = useAuth();
  const [searchParams] = useSearchParams();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized && localStorage.getItem('init')) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    if(searchParams.get("invitation") || searchParams.get("code")) {
      login(searchParams);
    }
   
    //return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    if(user && !user?.given_name && !user?.details?.givenName) {
      return <Navigate to={PATH_PROFILE.general.setup} />
    } else {
      return <Navigate to={requestedLocation} />;
    }
  }

  return(
        <FileUploadProvider>
            {children}
          </FileUploadProvider>
  )
}

export default AuthGuard;