import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import ProfileLayout from '../layouts/profile';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { AnalyticsSettingsProvider } from '../contexts/AnalyticsContext';
import { checkAndUpdateAnalyticsVersion } from '../utils/analyticsFeatures';
import FileManager from 'src/pages/FileManager';
import Register from 'src/pages/Register';
import { Explore } from 'src/sections/@dashboard/projects';
import Billing from 'src/pages/dashboard/Billing';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const [searchParams, setSearchParams] = useSearchParams();
  const errorDescription = searchParams.get("error_description");
  const error = searchParams.get("error");
  const timestamp = searchParams.get("timestamp");
  
  const { authorize } = useAuth();

  if(searchParams.get('code') &&  localStorage.getItem('config')) {
    authorize(searchParams);
  } 

  useEffect(() => {
    checkAndUpdateAnalyticsVersion();
  }, []);

  return useRoutes([
    // Dashboard Routes
    { 
      path: 'login',
      element: (
        <AuthGuard>
          <LoginPage />
        </AuthGuard>
      )
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <NFTPerks /> },
        { path: 'users', element: <MembersList /> },
        { path: 'billing', element: <Billing />  },
        { path: 'projects', element: <NFTPerks />  },

        { path: 'users/invitations', element: <InvitationsList /> },
        { path: 'nftholder', element: <CollectionList /> },
        { path: 'analytics', 
          children: [
            { path: 'nft/:nftCollectionId/:nftId', 
              element: 
              <AnalyticsSettingsProvider>
                <GeneralNftAnalytics />
              </AnalyticsSettingsProvider> 
            },
            { path: '', element: 
              <AnalyticsSettingsProvider>
                <GeneralAnalytics /> 
              </AnalyticsSettingsProvider>
            }
          ]
        },
        { path: 'project', 
          children: [
            { path: ':projectId/files', 
              children: [
                { path: '', element: 
                  <AnalyticsSettingsProvider>
                    <FileManager /> 
                  </AnalyticsSettingsProvider>
                }
              ]
            },
            { path: ':projectId/explore', element: 
              <AnalyticsSettingsProvider>
                <Explore /> 
              </AnalyticsSettingsProvider>
            },

            { path: 'offers/edit/:vendorID/:id', element: 
              <AnalyticsSettingsProvider>
                <NFTOffersNew /> 
              </AnalyticsSettingsProvider>
            }
          ]
        },
        { path: 'second-market', element: 
          <AnalyticsSettingsProvider>
            <GeneralAnalytics /> 
          </AnalyticsSettingsProvider> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <MembersList /> },
            { path: 'new', element: <NewMember /> },
            { path: ':name/edit', element: <NewMember /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'collection',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'list', element: <CollectionList /> },
            { path: 'new', element: <CreateNewCollection /> },
            { path: 'view/:id', element: <ViewCollection /> },
            { path: 'viewnft/:nftCollectionId/:nftId', element: <ViewNftPage /> },
            { path: 'edit/:id', element: <EditCollection /> },
            { path: ':name/edit', element: <CreateNewCollection /> },
            { path: 'addNft/:nftCollectionId', element: <NftCreate /> },
            { path: 'editExitingNft/:nftCollectionId/:nftId', element: <NftCreate /> },
          ],
        },
      ],
    },
    {
      path: 'profile',
      element: (
        <AuthGuard>
          <ProfileLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'setup', element: <ProfileSetup /> },
        { path: 'success', element: <ProfileSuccess /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'mint-nft', element: <MintNFT /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: 'error', element: <CustomErrorPage error={error} timestamp={timestamp} message={searchParams.get("error_description")} /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: 'filemanager', element: <FileManager /> },
        { path: 'register', element: <Register /> }
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: error ? <Navigate to={"/error?error_description=".concat(errorDescription ? errorDescription : "", "&error=", error)} replace /> : <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// DASHBOARD
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const NFTPerks = Loadable(lazy(() => import('../pages/dashboard/NFTPerks')));
const NFTOffers = Loadable(lazy(() => import('../pages/dashboard/NFTOffers')));
const NFTOffersNew = Loadable(lazy(() => import('../pages/dashboard/NFTOffersNew')));
const GeneralNftAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralNftAnalytics')));
const Home = Loadable(lazy(() => import('../pages/dashboard/Home')));
const LoginPage = Loadable(lazy(() => import('../pages/dashboard/LoginPage')));

//PROFILE
const ProfileSetup = Loadable(lazy(() => import('../pages/profile/ProfileSetup')));
const ProfileSuccess = Loadable(lazy(() => import('../pages/profile/ProfileSuccess')));

// NFT
const CreateNewCollection = Loadable(lazy(() => import('../pages/dashboard/CreateNewCollection')));
const NftCreate = Loadable(lazy(() => import('../pages/dashboard/CreateNewNft')));
const CollectionList = Loadable(lazy(() => import('../pages/dashboard/CollectionList')));
const ViewCollection = Loadable(lazy(() => import('../pages/dashboard/ViewCollection')));
const ViewNftPage = Loadable(lazy(() => import('../pages/dashboard/ViewNft')));
const EditCollection = Loadable(lazy(() => import('../pages/dashboard/EditCollection')));

// USER
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const MembersList = Loadable(lazy(() => import('../pages/dashboard/MembersList')));
const InvitationsList = Loadable(lazy(() => import('../pages/dashboard/InvitationsList')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const NewMember = Loadable(lazy(() => import('../pages/dashboard/NewMember')));

// OTHERS
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const MintNFT = Loadable(lazy(() => import('../pages/MintNFT')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const CustomErrorPage = Loadable(lazy(() => import('../pages/CustomErrorPage')));


// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductDetails'))
);
const EcommerceProductList = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductList'))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductCreate'))
);
const EcommerceProductEdit = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductEdit'))
);
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));

