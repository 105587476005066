import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { useNavigate, useSearchParams } from 'react-router-dom';
// @types
import { ActionMap, AuthState, AuthUser, Auth0ContextType, AuthTokens, AuthDetails, Roles } from '../@types/auth';
// routes
import { PATH_AUTH, PATH_PAGE, PATH_PROFILE } from '../routes/paths';
//
import { AUTH0_API, setLogo, LOGOUT_TIMESTAMP, UNAUTHRIZED_PAGES, GTAG } from '../config';
import { getByToken } from '../redux/slices/profile';
import { getRoles } from '../redux/slices/roles';

import ReactGA from 'react-ga';
// ----------------------------------------------------------------------

let auth0Client: Auth0Client | null = null;

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  tokens: null,
  details: null,
  roles: null
};

enum Types {
  init = 'INITIALIZE',
  login = 'LOGIN',
  logout = 'LOGOUT',
  authorize = 'AUTHORIZE',
  userUpdate = 'USERUPDATE'
}

type Auth0AuthPayload = {
  [Types.init]: {
    isAuthenticated: boolean;
    user: AuthUser;
    tokens: AuthTokens;
    details: AuthDetails;
    roles: Roles;
  };
  [Types.login]: {
    isAuthenticated?: boolean;
    user: AuthUser;
    tokens: AuthTokens;
    details: AuthDetails;
    roles: Roles;
  };
  [Types.userUpdate]: {
    user: AuthUser;
    details: AuthDetails;
  };
  [Types.logout]: undefined;
  [Types.authorize]: undefined;
};

type Auth0Actions = ActionMap<Auth0AuthPayload>[keyof ActionMap<Auth0AuthPayload>];

const reducer = (state: AuthState, action: Auth0Actions) => {
  if (action.type === Types.init) {
    const { isAuthenticated, user, tokens, roles, details } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      tokens,
      roles,
      details
    };
  }
  if (action.type === Types.login) {
    const { user, tokens, roles, details } = action.payload;
    return { ...state, isAuthenticated: true, user, tokens, roles, details };
  }
  if (action.type === Types.authorize) {
    return { ...state, isAuthenticated: true };
  }

  if (action.type === Types.userUpdate) {
    const { user, details } = action.payload;
    return { ...state, user: user, details };
  }
  if (action.type === Types.logout) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      tokens: null,
      details: null
    };
  }
  return state;
};

const AuthContext = createContext<Auth0ContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const setTokensHandler = async () => {
    if(auth0Client) {
      const accessToken = await auth0Client.getTokenSilently();
      const idToken = await auth0Client.getIdTokenClaims();
      localStorage.setItem("accessToken", accessToken ? accessToken : "");
      localStorage.setItem("idToken", idToken ? idToken?.__raw : "");
      return {"accessToken": accessToken, "idToken": idToken?.__raw};
    }
  }

  const getByTokenHandler = async () => {
    if(auth0Client) {
      return getByToken().then((response) => {
        if(!response) logout();
        if(response.error) {
          navigate({
            pathname: '/error',
            search: `?error=${response.error?.statusCode}&error_description=${response.error?.statusCode}&timestamp=${LOGOUT_TIMESTAMP}`,
          });
        }
        return response;
      });
    }
  }

  const getRolesHandler = async () => {
    if(auth0Client) {
      return getRoles().then((response) => {
        return response;
      });
    }
  }

  const createAuth0Object = () => {

    let config: any =  localStorage.getItem('config')
    if(config) {
      config = JSON.parse(config);
    }
    setLogo(config?.branding?.logo_url || '');
    auth0Client = new Auth0Client({
        client_id: AUTH0_API.clientId || '',
        domain: AUTH0_API.domain || '',
        audience: AUTH0_API.audience || '',
        organization: config?.id || '',
        redirect_uri: window.location.origin,
        cacheLocation:'localstorage',
        useRefreshTokens: true
      });
    return auth0Client;
  }

  const authUserUpdate = async () => {
    const user = await auth0Client?.getUser();
    const details = await getByTokenHandler();
    if(user) {
      dispatch({
        type: Types.userUpdate,
        payload: { user: user, details: details || null}
      });
    }
    
  }

  const initialize = async () => {
      try {
        auth0Client = createAuth0Object();
        await auth0Client.checkSession();
        const isAuthenticated = await auth0Client.isAuthenticated();
        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          const tokens = await setTokensHandler();
          const details = await getByTokenHandler()
          const roles = await getRolesHandler();
          dispatch({
            type: Types.init,
            payload: { isAuthenticated, user: user || null, tokens: tokens || null, details: details || null, roles: roles || null },
          });
          if(!user?.given_name && !details?.givenName) {
            navigate(PATH_PROFILE.general.setup);
          }
        } else {
          dispatch({
            type: Types.init,
            payload: { isAuthenticated, user: null, tokens: null, roles: null, details: null },
          });
          if(UNAUTHRIZED_PAGES.indexOf(window.location.pathname) < 0) {
            if(searchParams.get("organization")) {
              await auth0Client?.loginWithRedirect({
                organization: searchParams.get("organization") ?? '',
                organization_name: searchParams.get("organization_name") ?? '',
              });
            } else {
              await auth0Client?.loginWithRedirect();
            }
          }
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.init,
          payload: { isAuthenticated: false, user: null, tokens: null, roles: null, details: null },
        });
      }
    };

  useEffect(() => {
    if(localStorage.getItem('config')) {
      initialize();
    } else {
      checkInitialize();
    }
    if(GTAG.key) {
      ReactGA.initialize(GTAG.key);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [localStorage.getItem('config')]);

  const checkInitialize = () => {
     setTimeout(() => {
      if(localStorage.getItem('config')) { 
        initialize();
      } else {
        checkInitialize();
      }
    }, 3000);
  }

  const authorize = async (searchParams: any) => {
      localStorage.setItem('init', 'true');
      const redirectResult = await auth0Client?.handleRedirectCallback();
      const user = await auth0Client?.getUser();
      if(!redirectResult) {
        await auth0Client?.checkSession();
        await auth0Client?.loginWithRedirect(
          {code: searchParams.get("code") ?? '',}
        );
      }
      const tokens = await setTokensHandler();
      const details = await getByTokenHandler();
      const roles = await getRolesHandler();
      const isAuthenticated = await auth0Client?.isAuthenticated();
      localStorage.removeItem('init');
      if(isAuthenticated) {
        dispatch({ type: Types.login, payload: {isAuthenticated, user: user || null, tokens: tokens || null, details: details || null, roles: roles || null } });
      }
      if(user && !user?.given_name && !details?.givenName) {
        navigate(PATH_PROFILE.general.setup);
      }
  }

  const login = async (searchParams: any) => {
    if(localStorage.getItem('init')) {
      return;
    }
    await auth0Client?.checkSession();
    const isAuthenticated = await auth0Client?.isAuthenticated();
    if (searchParams.get("invitation")) {
      await auth0Client?.loginWithRedirect({
        invitation: searchParams.get("invitation") ?? '',
        organization: searchParams.get("organization") ?? '',
        organization_name: searchParams.get("organization_name") ?? '',
      });
    } else if(!isAuthenticated){ 
      await auth0Client?.loginWithRedirect();
    }

    if (isAuthenticated) {
      const user = await auth0Client?.getUser();
      const tokens = await setTokensHandler();
      const details = await getByTokenHandler()
      const roles = await getRolesHandler();
      dispatch({ type: Types.login, payload: { user: user || null, tokens: tokens || null, details: details || null, roles: roles || null } });
      if(!user?.given_name && !details?.givenName) {
        navigate(PATH_PROFILE.general.setup);
      }
    }
  };

  const logout = async () => {
    try {
      localStorage.clear();
      dispatch({ type: Types.logout });
      await auth0Client?.logout({
        returnTo:  window.location.origin
      });
    } catch (error) {
      
    }
    
  }; 

  const userObject = {
    id: state?.user?.sub,
    photoURL: state?.details?.profilePicture,
    email: state?.user?.email,
    displayName: state?.details?.givenName ? (state?.details?.givenName.concat(" ", (state?.details?.familyName ? state.details.familyName : ""))).trim() : state?.user?.nickname,
    role: state?.details?.partners ? state?.details?.partners[0].roles[0].displayName : "",
    details: state?.details,
    roles: state?.roles,
    tokens: state?.tokens
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'auth0',
        user: userObject,
        login,
        logout,
        authUserUpdate,
        authorize
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
