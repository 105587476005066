import axios from 'axios';
// config
import { HOST_API } from '../config';

function getAccessToken() {
  return localStorage.getItem('accessToken') ?? "";
}

function getIDToken() {
  return localStorage.getItem('idToken') ?? "";
}


const axiosInstance = axios.create({
  baseURL: HOST_API
});


axiosInstance.interceptors.request.use(
  async config => {
    config.headers = { 
      "access-token": getAccessToken(),
      "id-token": getIDToken()
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
