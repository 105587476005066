import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// form
import { useForm, Controller } from 'react-hook-form';
import useAuth from '../../../hooks/useAuth';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, FormControlLabel, Button, MenuItem } from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { UserManager } from '../../../@types/user';
// _mock
import { memberRoles } from '../../../_mock';
// components
import Label from '../../../components/Label';
import { CustomFile } from '../../../components/upload';
import { sendInvite, updateRole } from '../../../redux/slices/users';
import useLocales from 'src/hooks/useLocales';
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../components/hook-form';

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<UserManager, 'avatarUrl'> {
  avatarUrl: CustomFile | string | null;
}

type Props = {
  isEdit: boolean;
  currentUser?: UserManager;
  onClose?: any;
};

const buttonStyle = {
  width: '50%'
}

const roleDescriptionStyle = {
  fontSize: 12,
  color: "#919EAB"
}

export default function UserNewEditForm({ isEdit, currentUser, onClose }: Props) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [roles, setRoles] = useState(user?.roles ? user?.roles : memberRoles);
  const [role, setRole] = useState(0);
  const [userRole, setUserRole] = useState(user?.details?.partners ? user?.details?.partners[0].roles[0].id : 5);
  const NewUserSchema = Yup.object().shape({
    email: Yup.string().required(translate('users.add_edit.validations.email')).email(),
    role: Yup.string().required(translate('users.add_edit.validations.role')),
  });

  const defaultValues = useMemo(
    () => ({
      email: currentUser?.email || '',
      role: currentUser?.roleId.toString() || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentUser]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      if(isEdit) {
        updateRole(currentUser, userRole, Number(data.role)).then((response) => {
          response.data.statusCode == 4 ? 
          enqueueSnackbar(translate('users.add_edit.messages.role_update_success'))
          : enqueueSnackbar(translate('users.add_edit.messages.request_unsuccessful'), { variant: 'error' });
          onClose();
        }).catch((error) => {
          console.error(error);
          enqueueSnackbar(translate('users.add_edit.messages.request_unsuccessful'), { variant: 'error' })
        });
      } else {
        sendInvite(data.email, [Number(data.role)]).then((res) => {
          if(res.invitationSent) {
            enqueueSnackbar(!isEdit ? translate('users.add_edit.messages.new_success') : translate('users.add_edit.messages.edit_success'));
            onClose();
            navigate(PATH_DASHBOARD.general.invitations);
          } else {
            enqueueSnackbar(translate('users.add_edit.messages.request_unsuccessful_already_exist'), { variant: 'error' })
          }
        });
      }
    } catch (error) {
      enqueueSnackbar(translate('users.add_edit.messages.request_unsuccessful'), { variant: 'error' })
    }
  };

  const onCancel = () => {
    onClose();
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'block',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <Typography variant="subtitle1">
                {isEdit ? translate('users.add_edit.labels.heading.edit') : translate('users.add_edit.labels.heading.add')}
              </Typography>
              <Stack mt={3}>
                <RHFTextField name="email" disabled={isEdit} autoComplete={"off"} label={translate('users.add_edit.labels.email')} />
              </Stack>
              <Stack mt={2}>
                <RHFTextField
                  fullWidth
                  select
                  name="role" 
                  placeholder={translate('users.add_edit.labels.role')}
                  label={translate('users.add_edit.labels.role')}
                  SelectProps={{
                    MenuProps: {
                      sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                    },
                  }}
                  sx={{
                    textTransform: 'capitalize',
                    width: '100%',
                    whiteSpace:'break-spaces',
                    '& .MuiSelect-select': {
                      whiteSpace:'break-spaces'
                    }
                  }}
                >
                  {roles.map((option:any) => (
                    option.id >= userRole ? <MenuItem
                      key={option.id}
                      value={option.id}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        display: 'table-caption',
                        whiteSpace:'break-spaces',
                        width: { xs: '120%', md: '250%'}
                      }}
                    >
                      <Typography variant="subtitle2">
                        {option.displayName}
                      </Typography>
                      <Typography sx={roleDescriptionStyle}>
                        {option.description}
                      </Typography>
                    </MenuItem> : null
                  ))}
                </RHFTextField>
              </Stack>
              </Box>
            <Stack direction="row" justifyContent="center" spacing={1.5} sx={{ mt: 3 }}>
              <Button color="inherit" style={buttonStyle} variant="outlined" onClick={onCancel}>
                  {translate('cancel')}    
              </Button>
              <LoadingButton style={buttonStyle} type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? translate('users.add_edit.buttons.send_new_invite') : translate('users.add_edit.buttons.edit_invite')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
