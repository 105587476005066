import { Stack, InputAdornment, TextField, MenuItem, Button } from '@mui/material';
// components
import Iconify from 'src/components/Iconify';
import useLocales from 'src/hooks/useLocales';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
// ----------------------------------------------------------------------

type Props = {
  optionsRole: string[];
  filterName: string;
  filterRole: string;
  onFilterName: (value: string) => void;
  onFilterRole: (event: React.ChangeEvent<HTMLInputElement>) => void;
  getResultFn: () => void;

};

export default function ProjectSearchTabs({
  filterName,
  filterRole,
  onFilterName,
  onFilterRole,
  optionsRole,
  getResultFn
  ,
}: Props) {
  const { translate } = useLocales();
  const [nameError, setNameError] = useState<string>('');

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!filterName) {
      setNameError('Please enter searching text ');
      return;
    }
    getResultFn();
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onFilterName(value);
    setNameError('');
  };

  return (
    <form onSubmit={handleFormSubmit} >
      <Stack
        // spacing={1}
        direction={{ xs: 'column', sm: 'row' }}
        sx={{
          justifyContent: 'flex-start',
          gap: { xs: 2, md: 7 },
          flexWrap : 'wrap'
        }}
      >
        <TextField
          value={filterName}
          onChange={handleNameChange}
          placeholder={translate('projects.input.search')}
          sx={{ flexGrow: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={'eva:search-fill'}
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
            style: {
              height: "50px",
            },
          }}
          error={Boolean(nameError)}
          helperText={nameError}
        />

        <TextField
          fullWidth
          select
          value={filterRole}
          onChange={onFilterRole}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            ml: -5,
            maxWidth: { sm: 100 },
            textTransform: 'capitalize',
            flexGrow: 1,
          }}
          InputProps={{
            style: {
              height: "50px",
              width: "fit-content",
            },
          }}
        >
          {optionsRole.map(option => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option}
            </MenuItem>
          ))}
        </TextField>

        <Button
          variant="contained"
          type="submit" // Set button type to "submit"
          sx={{
            width: "120px",
            maxHeight:50,
          }}
        >
          {translate('projects.buttons.search')}
        </Button>
      </Stack>
    </form>

  );
}
