import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// _mock
import { memberRoles } from '../../_mock';

// ----------------------------------------------------------------------
const initialState: any = {
  isLoading: false,
  data: memberRoles,
  error: null,
  params: []
};

const slice = createSlice({
  name: 'params',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // ON SEND Invite
    onGetRoles(state) {
      const newPayload = {};
      state.params.push(newPayload);
    },
    // ON SEND Invite
    onGetUserRoleFromExternalIDP(state) {
      const newPayload = {};
      state.params.push(newPayload);
    },
  }
})

// Reducer
export default slice.reducer;

// Actions
export const { onGetRoles, onGetUserRoleFromExternalIDP } = slice.actions;


export const getRoles = async () => {
  const response = await axios.get('/user/role');
  return response.data.data;
};


export const getUserRoleFromExternalIDP = (roles: any, key: string) => {
  var role:any = {};
  if(roles) {
    roles.forEach((obj: any) => {
      if(obj.externalIDPIdentifier == key) {
        role = obj;
      }
    })
  }
  return role;
};


export const getRoleOptions = (roles: any) => {
  var roleNames:any[] = [''];
  if(roles) {
    roles.forEach((obj: any) => {
      roleNames.push(obj.displayName);
    })
  }
  return roleNames;
};