import { ReactNode, useEffect, useState } from "react";
import { useSelector, useDispatch } from '../redux/store';
import { useParams } from "react-router";
import FileUploadAccordion from "src/pages/FileManager/FileUploadAccordion";
import { MultiFilePreview } from "./upload";
import { getPresignedFilesUrl, preSignedFileUpload, uploadProjectFiles } from "src/redux/slices/projects";
import { useSnackbar } from "notistack";

interface FileUploadProviderProps {
    children: ReactNode;
}

export default function FileUploadProvider({ children }:FileUploadProviderProps) {
    const { projectId = '1' } = useParams<{ projectId?: string }>();
    const uploadingFiles = useSelector((state) => state.projects.uploadingFiles);
    const dispatch = useDispatch();

    const [fileProgress, setFileProgress] = useState({});

    const { enqueueSnackbar } = useSnackbar();

    let { files = [], startUploading = false } = uploadingFiles[projectId] || {};
    

    const getUploadProgress = (fileName: any) => (progress:any) => {
        let percentage = Math.floor((progress.loaded * 100) / progress.total)
  
        let uploadProgress = files?.map((_file: { name: any; progress: number; }) => {
          if(_file.name === fileName){
            _file.progress = percentage;
          }
          return _file;
        });
  
    setFileProgress((progress: any) => ({
        ...progress,
        [fileName]: percentage,
      }))
  
    }

    const handlePresignedFileUrl = async () => {
        let filesName = files.map( (file: { name: any; }) => file.name);

        let payload = {
        fileNames : filesName
        }
        await getPresignedFilesUrl(projectId, payload).then((response) => {
        const data = response ? response : [];
        console.log({ data })
        if(data.length){
            data.map((url: string, index: number) => {
            let fileName = files[index].name;
            var config = {
                onUploadProgress: getUploadProgress(fileName)
            };

            setFileProgress((progress) => ({
                ...progress,
                [fileName]: 0,
            }))
            
            preSignedFileUpload(url, files[index], config);
            });
        }
        }).catch(e => {
        enqueueSnackbar('Error in gettting presigned url', { variant: 'error' });
        });
    };


    const handleRemoveFile = (file: any) =>  {
        const filtered = files?.filter((_file: any) => _file !== file);
        dispatch(uploadProjectFiles({ projectId, files: filtered, startUploading : false }));

        const filteredNameWise = files?.filter((_file: { name: any; }) => _file.name !== file.name);
        setFileProgress(filteredNameWise);

    };

    const handleRemoveAllFiles = () => {
        dispatch(uploadProjectFiles({ projectId, files: [], startUploading : false }));
    }


    
    useEffect(() => {
        if(startUploading){
            handlePresignedFileUrl();
        }
    }, [startUploading]);

    return(
        <>
        { children }
        {
        startUploading && files.length &&  <FileUploadAccordion onRemoveAll={handleRemoveAllFiles}>
            <MultiFilePreview isUploading={true} handleUploadedFile={() => null} fileProgress={fileProgress} showProgress={false} files={files} showPreview={false} onRemove={handleRemoveFile} />
        </FileUploadAccordion>
        }
        </>
    )
}

