// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { ALL_USERS, OWNERS_AND_ADMINS, OWNERS } from '../../../config';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Home',
    items: [
      { title: 'Home', path: PATH_DASHBOARD.general.app, allowedUsers: ALL_USERS },
    ],
  },
  {
    subheader: 'Management',
    items: [
      { title: 'team', path: PATH_DASHBOARD.general.members, allowedUsers: OWNERS_AND_ADMINS },
    ],
  },
  {
    subheader: 'finance',
    items: [
      {
        title: 'billing',
        path: PATH_DASHBOARD.general.billing,
        allowedUsers: OWNERS_AND_ADMINS
      }
    ],
  },
];

export default navConfig;
