import { useState } from 'react';
// @mui
import { Button, Dialog, Box } from '@mui/material';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import useLocales from 'src/hooks/useLocales';
import Iconify from '../../../../components/Iconify';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
// ----------------------------------------------------------------------
import { PATH_DASHBOARD } from '../../../../routes/paths';
import MemberNewEditForm from '../../../../sections/@dashboard/user/MemberNewEditForm';

import { Theme } from '@mui/material/styles';
// const secondaryButtonStyle: React.CSSProperties = {
//   background: 'white',
//   marginLeft: '10px',
//   padding: '6px 0px',
// };

export default function UserTabs(props: any) {
  const {
    setDialogOpen,
    heading,
    dialogCloseCallback,
    linksPageName,
    linksPageNav,
    dialogOpen,
    isEdit,
    currentUser,
  } = props;

  const { translate } = useLocales();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const PAGES_OPTIONS = [
    {
      text: translate('users.list.tabs.team_members'),
      nav: PATH_DASHBOARD.general.members,
    },
    {
      text: translate('users.list.tabs.invited_members'),
      nav: PATH_DASHBOARD.general.invitations,
    },
  ];

  const onClose = () => {
    setDialogOpen(false);
    if (dialogCloseCallback) {
      dialogCloseCallback();
    }
  };

  return (
    <Box>
      <HeaderBreadcrumbs
        heading={heading}
        links={[]}
        action={
          <>
            <Button
              variant="contained"
              onClick={() => setDialogOpen(true)}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              { translate('users.list.tabs.invited_members') }
            </Button>

              
              {/* <Button
              variant="text"
              sx={{
                ml: 1,
                "&.MuiButtonBase-root": {
                  border: 'none'
                },
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent"
                }
              }}
              // onClick={() => setDialogOpen(true)}
              >
                <Iconify icon={'mdi:view-grid'} color="primary.lighter"  width={24} height={24} />
              </Button> */}

          </>
        }
      />
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <MemberNewEditForm
          currentUser={currentUser}
          isEdit={isEdit}
          onClose={() => onClose()}
        />
      </Dialog>
    </Box>
  );
}
