// @mui
import { styled } from '@mui/material/styles';
import { Button, Stack, Container, Typography, InputAdornment } from '@mui/material';
// hooks
import useCountdown from 'src/hooks/useCountdown';
// components
import Page from 'src/components/Page';
import InputStyle from 'src/components/InputStyle';
import SocialsButton from 'src/components/SocialsButton';
// assets
import { ComingSoonIllustration } from 'src/assets';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const CountdownStyle = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const SeparatorStyle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, 2.5),
  },
}));

// ----------------------------------------------------------------------

export default function Billing() {
  const countdown = useCountdown(new Date('07/07/2024 21:30'));

  return (
    <Page title="Coming Soon">
      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Coming Soon!
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            We are currently working hard on this page!
          </Typography>

          <ComingSoonIllustration sx={{ my: 10, height: 240 }} />

        </ContentStyle>
      </Container>
    </Page>
  );
}
