import { paramCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import useLocales from 'src/hooks/useLocales';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Typography,
  Grid,
  FormControlLabel
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// @types
import { ProjectManager } from '../../@types/projects';
// _mock_
import { _userList } from '../../_mock';
import { memberRoles } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import { getUsers, updateUserStatus } from '../../redux/slices/users';
import { deleteProjectDocuments, getDocuments } from 'src/redux/slices/projects';
import { getRoleOptions } from '../../redux/slices/roles';
import useAuth from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';

import { useSelector } from '../../redux/store';

import {
  TableNoData,
  TableEmptyRows,
  TableHeadCustomV2,
  TableSelectedActionsV2
} from '../../components/table';
// sections
import { UserTableToolbarV2, MemberTableRowV2, UserTabsV2, UserHeader } from '../../sections/@dashboard/user/list';
import MemberNewEditForm from '../../sections/@dashboard/user/MemberNewEditForm';
import KanbanConfirmDialog from '../../sections/@dashboard/kanban/KanbanConfirmDialog';
import { ROWS_PER_PAGE_OPTION } from 'src/config';

// ----------------------------------------------------------------------

export default function Folders() {
  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    dense,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeDense,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'createdAtUTC',
    defaultOrder: 'desc'
  });
  
  const initialValues: ProjectManager[] = [];


  const { projectId: projectParam, projectName } = useParams();
  const projectId = projectParam as any;
  
  const projectFiles = useSelector((state : { projects : { files : any[]}}) => state.projects.files);

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const { hash } = useLocation();

  const [tableData, setTableData] = useState(initialValues);
  
  const [currentUser, setCurrentUser] = useState(initialValues[0]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  
  const [userRole, setUserRole] = useState(user?.details?.partners[0]?.roles[0]?.id);

  const [openConfirm, setOpenConfirm] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const [loading, setLoading] = useState(true);

  const [filterName, setFilterName] = useState('');

  const [filterRole, setFilterRole] = useState('all');
  
  const { translate } = useLocales();

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs(hash ? decodeURI(hash).slice(1) : 'all');

  const [roles, setRoles] = useState(user?.roles ? user?.roles : memberRoles);
  
  const [ selectedDocumentRow, setSelectedDocumentRow] = useState(null);

  const ROLE_OPTIONS = getRoleOptions(roles);


  const TABLE_HEAD = [
    { id: 'name', label: translate('name'), align: 'left' },
    { id: 'type', label: translate('type'), align: 'left' },
    { id: 'size', label: translate('size'), align: 'left' },
    { id: 'createdAtUTC', label: translate('Uploaded Date'), align: 'center' },
    { id: 'status', label: translate('status'), align: 'center' },
    // { id: 'shared', label:  translate(''), align: 'left' },
    { id: '' },
  ];

  const STATUS_OPTIONS = [translate('users.list.tabs.all'), translate('users.list.tabs.active'), translate('users.list.tabs.inactive'), translate('users.list.tabs.invited_members')];

  const getDocumentsHandler = async () => {
    setLoading(true);
    await getDocuments(Number(projectId)).then((response) => {
      const files = response && response.data.data ? response.data.data : [];
      if(!Boolean(files)){
        throw Error('Data not found');
      }
      setTableData(files);
      setLoading(false);
    }).catch(e => {
      setLoading(false);
    });
  }

  useEffect(() => {
    getDocumentsHandler();
  }, []);

  useEffect(() => {
    if( tableData && tableData?.length !== projectFiles[projectId]?.length){
      setTableData(projectFiles[projectId]);
    }
  },[projectFiles, projectId]);


  useEffect(() => {
    setPage(0);
  }, [filterStatus]);

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterRole(event.target.value);
    onChangePage(event, 0);
  };

  const handleActiveInactiveRow = async (row: any) => {
    setOpenConfirm(true);
    setSelectedItem(row);
  };

  const handleDeleteRow = async (row: any) => {
    setOpenConfirm(true);
    setSelectedItem(row);
    setSelectedDocumentRow(row);
  };

  const onCloseConfirm = () => {
    setOpenConfirm(false);
    setSelectedItem(null);
  }

  const onConfirm = async () => {
    if(selectedDocumentRow === null ) return;
    const { id }  = selectedDocumentRow;
    const res = await deleteProjectDocuments(projectId, id);
    const { statusCode } = res;
      if(statusCode === 1 ) {
        enqueueSnackbar(translate('nft_perks.success.delete_document'));
        setOpenConfirm(false);
        getDocuments(projectId);
      } else {
        enqueueSnackbar(translate('nft_perks.errors.delete_document'), { variant: 'error' });
      }
      
    // updateUserStatus(selectedItem, userRole).then((res) => {
    //   if(res.success) {
    //     res.isActive ? enqueueSnackbar(translate('users.add_edit.messages.inactivate')) : enqueueSnackbar(translate('users.add_edit.messages.activate'));
    //     getDocumentsHandler();
    //     setSelectedItem(null);
    //     setOpenConfirm(false);
    //   } else {
    //     enqueueSnackbar(translate('users.add_edit.messages.request_unsuccessful'), { variant: 'error' })
    //   }
    // });
  }

  const handleDeleteRows = (selected: string[]) => {
    const NonSelectedRows = tableData.filter((row: { id: string }) => !selected.includes(row.id));
    selected.map(async (id) => {
      const res = await deleteProjectDocuments(projectId, id);
      const { statusCode } = res;
      if(statusCode === 1 ) {
        // enqueueSnackbar(translate('nft_perks.success.delete_document'));
        // setOpenConfirm(false);
        // getDocuments(projectId);
        // do nothing
      } else {
        enqueueSnackbar(`${id} : `+translate('nft_perks.errors.delete_document'), { variant: 'error' });
      }
    })
    setSelected([]);
    setTableData(NonSelectedRows);
  };

  const handleEditRow = (user: any) => {
    setCurrentUser(user);
    setIsEdit(true);
    setDialogOpen(true);
  };

  const handleDialogClose = (val:boolean) => {
    setDialogOpen(val);
    setCurrentUser(initialValues[0]);
    setIsEdit(false);
  };

  const dataFiltered = tableData &&  applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  }) || [];


  const denseHeight = dense ? 60 : 80;

  const isNotFound =
    (dataFiltered && !dataFiltered.length && !!filterName) ||
    (dataFiltered && !dataFiltered.length && !!filterRole) ||
    (dataFiltered && !dataFiltered.length && !!filterStatus);

  return (
    <Page title={translate('projects.document.title')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>

      <Grid container spacing={0} mt={2}>
              <Grid item xs={6} md={12} mb={5}>
                <UserTableToolbarV2
                  filterName={filterName}
                  filterRole={filterRole}
                  onFilterName={handleFilterName}
                  onFilterRole={handleFilterRole}
                  optionsRole={ROLE_OPTIONS}
                />
              </Grid>
              {/* <Grid item xs={6} md={4}>
                <UserHeader dialogCloseCallback={getDocumentsHandler} isEdit={isEdit} currentUser={currentUser} dialogOpen={dialogOpen} setDialogOpen={(val:boolean) => handleDialogClose(val)}  linksPageNav={PATH_DASHBOARD.general.members} linksPageName={translate('users.list.page_name')}  />
              </Grid> */}
      </Grid>

        {/* heading={translate('users.list.title')} */}


        <Box />

        <Card>
          {/* <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs> */}

          <Divider />

          <Scrollbar>
            {loading && <Box textAlign="center"><CircularProgress /></Box>}
            {!loading && <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActionsV2
                  numSelected={selected.length}
                  rowCount={tableData && tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData && tableData.map((row: { id: string }) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={'medium'} sx={{
                "Table" : {
                  ".MuiTableCell-root" : {
                    padding: 0,
                    margin: 0,
                    minWidth: 500,
                    maxWidth: 500,
                  }
                }
              }}>
                <TableHeadCustomV2
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData && tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData && tableData.map((row: { id: string }) => row.id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered && dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (  
                      <MemberTableRowV2
                        key={row.name+index}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row)}
                        onEditRow={() => handleEditRow(row)}
                      />
                    )
                    )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData && tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>}
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>

          <TablePagination
              rowsPerPageOptions={ROWS_PER_PAGE_OPTION}
              component="div"
              count={dataFiltered && dataFiltered?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
            
            {/* <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' }, cursor : 'pointer' }}
            /> */}

          </Box>
        </Card>
      </Container>
      <KanbanConfirmDialog
          open={openConfirm}
          onClose={onCloseConfirm}
          title={
            <Typography>
              {translate('users.list.buttons.delete_confirmation')}
            </Typography>
          }
          actions={
            <>
              <Button variant="outlined" color="inherit" onClick={onCloseConfirm}>
                {translate('users.list.buttons.cancel')}
              </Button>
              <Button variant="contained" color="error" onClick={onConfirm}>
                {translate('users.list.buttons.yes')}
              </Button>
            </>
          }
        />
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  tableData: ProjectManager[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = tableData && tableData.map((el, index) => [el, index] as const);

  stabilizedThis && stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis && stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) => {
        //FIXME: to handle null fileName from haystack
        if(item.name === null) return false;
        return item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
      }
    );
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter((item: Record<string, any>) => item.status === filterStatus);
  }

  if (filterRole !== 'all') {
    tableData = tableData.filter((item: Record<string, any>) => item.role === filterRole);
  }

  return tableData;
}

